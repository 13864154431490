import {React, useState, useEffect, useContext} from "react";
// import Sidebar from "../../components/Sidebar";
// import Services from "../../Routes"
// import ApiStatusAlert from "../../components/ApiStatusAlert";
import  DashboardComponent from "../components/Dashboard/Dashboard"
import { ConfigProvider } from "antd";
import FloatingButton from "../components/FloatingButton";
import { useNavigate, useLocation, useHistory } from 'react-router-dom';
import {makeRequest,makeRequest2} from "../api"
import AreaAnalysis from "../components/Dashboard/AreaAnalysis/AreaAnalysis";
import Filter from "../components/Dashboard/Filter";
import Carousels from "../components/Login/Carousel";

const Dashboard = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [refreshToken , setrefreshToken] = useState([]);
    const [jsondata , setjsondata] = useState([]);
    
    useEffect(() => {
    const handlePopState = (event) => {
      navigate('/dashboard');
    };

    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate]);
    
    

    // const Logout = async () => {
    //     const config = {
    //         headers: {
    //           'Authorization': `Bearer ${localStorage.access_key}`,
    //           'Content-Type': 'application/json'
    //         }
    //       };
        
    //       const jsondata = {
    //         refresh: localStorage.refresh
    //       };
    //     try {
    //       console.log("json", jsondata);
    //       console.log("config", config);
    //       const result = await makeRequest2("post", `/user_profile/logout/`,jsondata,config);
    //       console.log("logout clicked", jsondata);
    //       navigate("/");
    //       window.location.reload();
    //     } catch (error) {
    //         console.log("json", jsondata);
    //         console.log("config", config);
    //     }
    //   };

    // const handleLogout = () => {
    //     Logout();
    // };


    return (
        <ConfigProvider
            theme={{
                components: {
                    Button: {
                        // colorPrimary:"#000",
                        // defaultBg: "#000",
                        // colorPrimaryHover: "#000"
                    },
                },
            }}
        >
            <div className="Dashboard" style={{margin: "4vh 2vw"}}>
                {/* <Sidebar />
        <ApiStatusAlert/>
      <Services/> */}

                {/* Dashboard */}
                <DashboardComponent/>
                {/* <FloatingButton onLogout={handleLogout}/> */}
            </div>
        </ConfigProvider>
    );

};

export default Dashboard;
