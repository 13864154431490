import React, { useEffect, useState } from 'react';
import axios from 'axios';

const TopContribution = ({ selectedLocation, selectedTime }) => {
    const [regions, setRegions] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            const timeFrameMap = {
                "Past 1 Month": "1_month",
                "Past 3 Months": "3_months",
                "Past 6 Months": "6_months",
                "Past 1 Year": "1_year",
            };
            const timeParam = timeFrameMap[selectedTime] || "6_months";

            const url = `${process.env.REACT_APP_BE_URL}/emissions/max-measurements/`;

            try {
                const response = await axios.get(url, {
                    params: {
                        location: selectedLocation,
                        timeframe: timeParam,
                    },
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_key")}`,
                        "Content-Type": "application/json",
                    },
                });
                setRegions(response.data.data); // Update regions with API response
            } catch (err) {
                // Set dummy data in case of API failure
                setRegions([
                    {
                        max_location: selectedLocation || "NA",
                        gas: "Ozone",
                        max_measurement: 0.1329,
                        unit: "mol/m²",
                        threshold_diff: "0.1179 more than threshold",
                    },
                    {
                        max_location: selectedLocation || "NA",
                        gas: "Sulfur Dioxide",
                        max_measurement: 0.0005,
                        unit: "mol/m²",
                        threshold_diff: "0.0003 more than threshold",
                    },
                    {
                        max_location: selectedLocation || "NA",
                        gas: "Methane",
                        max_measurement: 0.1427,
                        unit: "mol fraction",
                        threshold_diff: "0.0498 more than threshold",
                    },
                    {
                        max_location: selectedLocation || "NA",
                        gas: "Nitrogen Dioxide",
                        max_measurement: 0.0008,
                        unit: "mol fraction",
                        threshold_diff: "0.0002 more than threshold",
                    },
                    {
                        max_location: selectedLocation || "NA",
                        gas: "Carbon Monoxide",
                        max_measurement: 0.0548,
                        unit: "mol fraction",
                        threshold_diff: "0.0003 more than threshold",
                    },
                ]);
            } finally {
                setLoading(false); // End loading
            }
        };

        fetchData();
    }, [selectedLocation, selectedTime]);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="contribution-regions-container">
            <div className="emission-heading">Top contributing regions</div>
            <ul className="region-list">
                {/* Column Headers */}
                <div className="region-headers">
                    <div style={{ width: '40%' }}>Region</div>
                    <div style={{ width: '30%' }}>Gas</div>
                    <div style={{ width: '35%' }}>Max Emission rate</div>
                </div>

                {/* Region Data */}
                {regions.map((region, index) => (
                    <li key={index} className="region-item">
                        <div className="region-location">{region.max_location}</div>
                        <div className="region-name">{region.gas}</div>
                        <div className="region-value-container">
                            <div className="region-value">
                                {parseFloat(region.max_measurement).toFixed(3)} {region.unit}
                            </div>
                            <div className="region-threshold">
                                {region.threshold_diff}
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default TopContribution;
