import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../css/AppPage.css"; // Import the CSS file for styling
import { ReactComponent as AppNameLogo } from "../../assets/SVG/forest_img.svg";
import { ReactComponent as Login3 } from "../../assets/SVG/login3.svg";
import Login1 from "../../assets/SVG/login1.svg";
import Login2 from "../../assets/SVG/login2.svg";
import Valiancelogo from "../../assets/Images/logo 1.png";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import TadobaContext from "../Context/TadobaContext";
import { Button, Spin } from "antd";
// import { makeRequest } from "../../api";
import Carousels from "./Carousel";
import Forgot_Password from "./Forgot_Password";
import { loginUser } from "../../features/Userslice";

const AppPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState("");
  const [type, setType] = useState("password");
  const api = useContext(TadobaContext);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [email, setEmail] = useState("");

  const user = useSelector((state) => state.user);

  useEffect(() => {
    // If user is logged in, redirect to the desired page.
    if (user.login) {
      navigate('/section'); // Or any other page you want to navigate to
    }
  }, [user, navigate]);

  function ValidateEmail(input) {
    var validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (input.match(validRegex)) {
      return true;
    } else {
      return false;
    }
  }

  const showPasswordHandler = (event) => {
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  };

  const credentialChangeHandler = (event) => {
    setError("");
    setMessage("");
    if (event.target.name === "email") {
      setEmail(event.target.value);
    }
    if (event.target.name === "password") {
      if (email === "") {
        setError("email");
        setErrorMessage("Email Id Required");
      } else if (ValidateEmail(email) === false) {
        setError("email");
        setErrorMessage("Invalid Email Id");
      }
      setPassword(event.target.value);
    }
  };

  useEffect(() => {
    if (message?.includes("Enter a valid email address")) {
      setError("email");
      setErrorMessage("Invalid Email Id");
    } else if (message?.includes("user")) {
      setErrorMessage("User not available");
      setError("email");
    } else if (message?.includes("Incorrect")) {
      setError("password");
      setErrorMessage("Invalid Password");
    } else if (
      message?.includes(
        "Password must contain at least 1 letter, 1 number, 1 special character, and be at least 10 characters long."
      )
    ) {
      setErrorMessage(
        "Password must contain at least 1 letter, 1 number, 1 special character, and be at least 10 characters long."
      );
      setError("password");
    } else if (
      message?.includes(
        "Account temporary is blocked on this IP address. Please contact administrator"
      )
    ) {
      setDisabled(true);
    } else if (
      message?.includes("OTP is not valid") ||
      message?.includes("otp value is required") ||
      message?.includes("otp_attempts")
    ) {
      setErrorMessage("Invalid Otp");
      setError("Otp");
    } else {
      setError("");
    }
  }, [message]);

  const forgotEventHandler = () => {
    navigate("/forgot_password");
  };

  const loginRequest = (loginData) => {
    setLoading(true);
  
    dispatch(loginUser(loginData)).then((result) => {
      if (result.payload) {
        const data = result.payload;
        setLoading(false); // Stop loading after login
  
        if (data.twoFaStatus) {
          navigate('/verify2fa'); // Navigate immediately
        } else {
          navigate('/section');
        }
      } else {
        setErrorMessage("Invalid Email ID or Password");
        setLoading(false); // Stop loading if login fails
      }
    });
  };  

  const LoginHandler = (e) => {
    e.preventDefault();
    if (email === "") {
      setErrorMessage("The field is required");
      setError("email");
    } else if (password === "") {
      setErrorMessage("The field is required");
      setError("password");
    } else {
      loginRequest({ email,password });
    }
  };

  const passwordFocusHandler = () => {
    if (email === "") {
      setErrorMessage("The field is required");
      setError("email");
    } else if (ValidateEmail(email) === false) {
      setError("email");
      setErrorMessage("Invalid Email Id");
    }
  };

  const encryptAES = (plaintext) => {
    const secretKey = "RUFqlY38xUWND5Yt";
    const key = CryptoJS.enc.Utf8.parse(secretKey);
    const iv = CryptoJS.lib.WordArray.random(16);
    const encrypted = CryptoJS.AES.encrypt(plaintext, key, {
      iv: iv,
      padding: CryptoJS.pad.Pkcs7,
    });
    const combined = CryptoJS.lib.WordArray.create();
    combined.concat(iv).concat(encrypted.ciphertext);
    return combined.toString(CryptoJS.enc.Base64);
  };

  const encryptPayload = (payload, secretKey) => {
    const encryptedPayload = {};
    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        encryptedPayload[key] = encryptAES(payload[key]);
      }
    }
    return encryptedPayload;
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      LoginHandler(event);
    }
  };

  return (
          <div className="login">
            <div className="company-container">
              <img
                className="forest_img"
                src={require("../../assets/Images/pench_logo.jpeg")}
              />
              <div className="Application-name">
                <p>Deforestation Monitoring</p>
                <ul>
                  <li>
                    <span>Powered By</span>
                  </li>
                  <li>
                    <img src={Valiancelogo}></img>
                    
                  </li>
               
                </ul>
              </div>
            </div>
            <div className="login-title">
              <span>Hey there!</span>
              <p>Please login using your credentials.</p>
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "32px" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "24px",
                }}
              >
                <div className="Login_Input">
                  <label className="email_icon" htmlFor="login">
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    onKeyDown={handleKeyDown}
                    placeholder="Enter your email"
                    className={error === "email" ? "input_warning" : ""}
                    value={email}
                    autoFocus="autofocus"
                    onChange={credentialChangeHandler}
                    id="login"
                  />
                  {error === "email" && <label id="warning">{errorMessage}</label>}
                </div>
                <div className="Login_Input">
                  <label className="password_icon" htmlFor="password">
                    Password
                  </label>
                  <div className="password">
                    <input
                      type={type}
                      name="password"
                      onKeyDown={handleKeyDown}
                      onFocus={passwordFocusHandler}
                      className={error === "password" ? "input_warning" : ""}
                      value={password}
                      onChange={credentialChangeHandler}
                      placeholder="Enter your password"
                      id="password"
                    />
                    {type === "text" ? (
                      <img
                        src={require("../../assets/Icons/eye.png")}
                        onClick={showPasswordHandler}
                        alt=""
                      />
                    ) : (
                      <img
                        onClick={showPasswordHandler}
                        src={require("../../assets/Icons/eye-off.png")}
                        alt=""
                      />
                    )}
                  </div>
                  {error === "password" ? (
                    <label id="warning">{errorMessage}</label>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="remember-forgot">
                <span onClick={forgotEventHandler}>Forgot Password ?</span>
              </div>
              <div
                className={
                  !disabled
                    ? "Login_Button"
                    : "Login_Button login_button_disabled"
                }
              >
                <Spin spinning={loading}>
                  {" "}
                  <button disabled={disabled} onClick={LoginHandler}>
                    Log In
                  </button>
                </Spin>
              </div>
            </div>
          </div>
  );
};

export default AppPage;

