import React, { useState } from "react";
import AreaAnalysisIcon from "../../../assets/SVG/areaAnalysisIcon.svg";
import CloseIcon from "../../../assets/SVG/close.svg";
import Iicon from "../../../assets/SVG/Iicon.svg";
import "../../../css/areaAnalysis.css";
import { makeRequest } from "../../../api";
import axios from "axios";
import ApexLineChart from "./LineChart.jsx";
import PieChart from "./PieChart.jsx";
import { Button, Modal, DatePicker, Space, Select, TimePicker } from "antd";
import dayjs from "dayjs";
const dateFormat = "YYYY-MM-DD";
// const dateFormat = "DD-MM-YYYY";
const displayFormat = "DD-MM-YYYY";
const internalFormat = "YYYY-MM-DD";
const { Option } = Select;

const AreaAnalysis = ({
  apiResponse,
  setToDate,
  setFromDate,
  setSelectedCause,
  name,
  chart,
  setfilteroption,
  fromDate,
  toDate,
}) => {

  return (
    <div className="AreaAnalysisComp">
      <div className="area-analysis-header">
        <div className="area_header_left">
          <ul className="area_header_left_list">
            <li className="area_header_left_icon">
              <img src={AreaAnalysisIcon} alt="AreaAnalysisIcon" />
            </li>
            <li>
              <p className="area_header_left_text">{name} Analysis</p>
            </li>
          </ul>
        </div>
        <div className="area_header_right">
          <ul className="area_header_right_list">
            <li className="area_header_right_text"></li>
          </ul>
        </div>
      </div>

      <div className="area-analysis-chart" key={chart}>
        {chart === "linechart" ? (
        
          <ApexLineChart
          
            response={apiResponse}
            setSelectedCause={setSelectedCause}
          />
        ) : (
          <PieChart response={apiResponse} />
        )}
      </div>

      <div className="area-analysis-footer">
        <img src={Iicon} alt="Iicon" />
        {chart === "linechart" ? (
          <p className="area-analysis-footer-text">
            The chart tells about the number of coordinates of places,
            increased/decreased in the past months. It helps to get an
            estimation status of increasing/ decreasing deforestation.{" "}
          </p>
        ) : (
          <p className="area-analysis-footer-text">
            The pie chart tells about the causes of increase or decrease in
            number of coordinates in the past month of various causes like
            Farming, Build Up, and Lake which contribute to deforestation.{" "}
          </p>
        )}
      </div>
    </div>
  );
};

export default AreaAnalysis;
