import React from 'react';
import Navbar from '../../Emission/Navbar';
import Sidebar from '../../Emission/Sidebar';
import ReportGenerated from '../Report/ReportGenerated';

const ReportDetails = () => {
  return (
    <div className="emission-page-container">
        <div className="navbar-container">
            <Navbar />
        </div>
        <div className="content-container">
            <div className="emission-sidebar-container">
                <Sidebar />
            </div>
            <div className="main-content-container">
                <ReportGenerated />
            </div>
        </div>
    </div>
  )
}

export default ReportDetails
