import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation , useNavigate  } from 'react-router-dom';  // Importing useLocation hook
import valiance from '../../assets/Emission/Navbar/valiance.svg';
import mainlogo from '../../assets/Emission/Navbar/mainlogo.svg';
import alertIcon from '../../assets/Emission/Navbar/alert-icon.svg';
import profilePic from '../../assets/Emission/Navbar/profile-pic.jpg';

const Navbar = () => {
  const location = useLocation(); 
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState(null);

  useEffect(() => {
    // Fetch user details
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BE_URL}/user_profile/profile/`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('access_key')}`,
            },
          }
        );
        setUserDetails(response.data); // Set the fetched user details
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    fetchUserDetails();
  }, []);
  
  // Conditionally render content based on the current route
  const isMapWiseRoute = location.pathname === '/map-wise';
  const isEmissionRoute = location.pathname === '/dashboard/emission';
  const isReportRoute = location.pathname === '/report' || location.pathname === '/report-details';
  const isProfileRoute= location.pathname === '/profile';

    const handleLogoClick = () => {
      navigate('/section');  // Navigate to /section when the logo is clicked
    };
    
  return (
    <div className='main'>
      <div className="container">
        <img src={mainlogo} alt="main logo" className="main-logo" onClick={handleLogoClick}/>
      </div>
      <div className="logo-wrapper">
        <div className="logo-column-1"  onClick={handleLogoClick}>
          <p>Emission Monitoring</p>
          <img src={valiance} alt="Valiance" className="valiance" />
        </div>

        <div className="divider"></div> {/* Vertical Line */}

        <div className="logo-column-2">
          {isMapWiseRoute && (
            <>
              <p className='map-heading'>Map-wise Analytics</p>
              <p className='map-subheading-mapwise'>
                Explore a region on the map to know about its analytics and detailed insights for its sub-regions.
              </p>
            </>
          )}
         </div>

         <div className="logo-column-2">    
            {isEmissionRoute && (
              <>
                <p className='map-heading'>Emission Analytics</p>
                <p className='map-subheading'>
                  Track GHG emissions over time and refine results with location and date filters.
                </p>
              </>
            )}
         </div>

         <div className="logo-column-2">    
            {isReportRoute && (
              <>
                <p className='map-heading'>Report Generation</p>
                <p className='map-subheading'>
                Download a personalized report tailored to your selected filters in just a few clicks.                
                </p>
              </>
            )}
         </div>
         <div className="logo-column-2">    
            {isProfileRoute && (
              <>
                <p className='map-heading'>Admin Profile</p>
                <p className='map-subheading'>
                View and update your profile details, preferences, and settings.
                </p>
              </>
            )}
         </div>


        {/* Profile */}
        <div className="profile-wrapper">
          {/* <img src={alertIcon} alt="Alert Icon" className="alert-icon" /> */}
          <img src={profilePic} alt="Profile Picture" className="profile-pic" />
          <div className="profile-details">
            <p className="profile-name"> 
              Hi {userDetails ? `${userDetails.first_name} ! ` : 'Admin'}
            </p>
            {/* <div className="profile-role-wrapper">
              <img src={crownIcon} alt="Crown Icon" className="crown-icon" />
              <p className="profile-role">Admin</p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
