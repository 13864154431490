import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Select } from 'antd';
import { IoLocationOutline } from 'react-icons/io5';
import { MdOutlineCalendarToday } from 'react-icons/md';
import Map from './MapComponent';
import AnalyticsComponent from './AnalyticsComponent';

const gasTypes = [
  { value: 'Carbon Monoxide', label: 'Carbon Monoxide CO' },
  { value: 'Sulfur Dioxide', label: 'Sulfur Dioxide SO₂' },
  { value: 'Nitrogen Dioxide', label: 'Nitrogen Dioxide NO₂' },
  { value: 'Methane', label: 'Methane CH₄' },
  { value: 'Ozone', label: 'Ozone O₃' }
];

const gasClassMap = {
  'Carbon Monoxide': 'co',
  'Sulfur Dioxide': 'so2',
  'Nitrogen Dioxide': 'no2',
  'Methane': 'ch4',
  'Ozone': 'o3'
};

const timeframes = [
  { value: '1_month', label: 'Past 1 Month' },
  { value: '3_months', label: 'Past 3 Months' },
  { value: '6_months', label: 'Past 6 Months' },
  { value: '1_year', label: 'Past 1 Year' }
];

const MapDashboard = () => {
  const [selectedGas, setSelectedGas] = useState('Carbon Monoxide');
  const [location, setLocation] = useState('Madhya Pradesh');
  const [timeframe, setTimeframe] = useState('6_months');
  const [locations, setLocations] = useState([]);
  const [analyticsData, setAnalyticsData] = useState({
    ghgRates: null,
    forestFireTrend: null,
    emissionTrend: null,
    subRegion: null
  });
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const token = localStorage.getItem("access_key");
        if (!token) {
          console.error("No access token found.");
          return;
        }

        const response = await axios.get(
          `${process.env.REACT_APP_BE_URL}/forest_fire/location-list/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data && Array.isArray(response.data.data)) {
          setLocations(response.data.data);
        } else {
          console.error("Unexpected API response format:", response.data);
        }
      } catch (error) {
        console.error("Error fetching locations:", error);
      }
    };

    fetchLocations();
  }, []);

  const fetchAnalyticsData = async () => {
    try {
      setError(null);
      const token = localStorage.getItem('access_key');
  
      if (!token) {
        console.error('No token found in localStorage');
        return;
      }
  
      const headers = {
        Authorization: `Bearer ${token}`
      };
  
      const [ghgRes, fireTrendRes, emissionRes,subRegionData] = await Promise.all([
        axios.get(
          `https://pench-deforestation-be-rxncfgcjfa-el.a.run.app/forest_fire/current-ghg-rates/?location=${location}`,
          { headers }
        ),
        axios.get(
          `https://pench-deforestation-be-rxncfgcjfa-el.a.run.app/forest_fire/forest-fire-trend/?location=${location}&timeframe=${timeframe}`,
          { headers }
        ),
        axios.get(
          `https://pench-deforestation-be-rxncfgcjfa-el.a.run.app/forest_fire/emission-trend/?location=${location}&timeframe=${timeframe}&gas=${selectedGas}`,
          { headers }
        ),
        axios.get(
          `https://pench-deforestation-be-rxncfgcjfa-el.a.run.app/forest_fire/sub-regional-analysis/?timeframe=${timeframe}`,
          { headers }
        ),
      ]);
  
      setAnalyticsData({
        ghgRates: ghgRes.data,
        forestFireTrend: fireTrendRes.data,
        emissionTrend: emissionRes.data,
        subRegion: subRegionData.data
      });
    } catch (error) {
      setError('Failed to fetch data. Please try again later.');
      console.error('Error fetching analytics data:', error);
    }
  };

  useEffect(() => {
    fetchAnalyticsData();
  }, [location, timeframe, selectedGas]);

  return (
    <div className='map-view-main-page'>
      <div className='map-view-main'>
        <div className='map-view-container'>
          <header className='map-view-header'>
            <h3>Map View</h3>
            <p>Select gas type to display on the heatmap.</p>
          </header>
          <div className='gas-type-selector'>
            {gasTypes.map(({ value, label }) => (
              <label key={value} className={selectedGas === value ? `active ${gasClassMap[value]}` : gasClassMap[value]}>
                <input type='radio' name='gas' value={value} checked={selectedGas === value} onChange={() => setSelectedGas(value)} />
                {label}
              </label>
            ))}
          </div>
        </div>
        <div className='dropdown-wrapper'>
          <IoLocationOutline className='dropdown-icon' />
          <div className='dropdown-content'>
            <p className='forest-dropdown-label'>Location</p>
            <Select defaultValue={location} onChange={setLocation} className='custom-select'>
              {locations.map(loc => <Select.Option key={loc} value={loc}>{loc}</Select.Option>)}
            </Select>
          </div>
        </div>
        <div className='dropdown-wrapper'>
          <MdOutlineCalendarToday className='dropdown-icon' />
          <div className='dropdown-content'>
            <p className='forest-dropdown-label'>Timeframe</p>
            <Select defaultValue={timeframe} onChange={setTimeframe} className='custom-select'>
              {timeframes.map(({ value, label }) => <Select.Option key={value} value={value}>{label}</Select.Option>)}
            </Select>
          </div>
        </div>
      </div>
      <div className='forest-dashboard-content'>
        {error && <div>{error}</div>}
        {!error && analyticsData && (
          <>
            <div className='map-container-forest'><Map  selectedGas={selectedGas}/></div>
            <AnalyticsComponent analyticsData={analyticsData} />
          </>
        )}
      </div>
    </div>
  );
};

export default MapDashboard;

