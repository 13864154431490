import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React from 'react';
import Services from './Routes';
import Dashboard from './container/Dashboard';
import Section from './container/Section';
import Login from "./container/Login";
import AppPage from "./components/Login/LoginDetails"
import './App.css';
import './css/index.css'
import NavigationBar from "./components/NavigationBar";
import Carousels from "./components/Login/Carousel"
import OtpPage from "./components/Login/OtpPage";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/verify2fa" element={<OtpPage />} />
          <Route path="/" element={<Login />} />
          <Route path="/*" element={<Services />} />
        </Routes>
      </Router>
    </div>
  );
}  

export default App;
