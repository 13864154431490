import React from "react";

const colorMapping = {
  low: "#173F5F33",
  low_medium: "#173F5F66",
  medium: "#173F5F99",
  medium_high: "#173F5FCC",
  high: "#173F5F",
};

const MapIndicator = ({ data }) => {
  if (!data) return <p style={{ color: "#898989", fontSize: "14px" }}>Loading indicator data...</p>;

  return (
    <div
      style={{
        width: "100%",
        maxWidth: "300px",
        margin: "0 auto",
        padding: "16px",
      }}
    >
      <h4
        style={{
          fontSize: "14px",
          fontWeight: "400",
          marginBottom: "8px",
          color: "#898989",
          textAlign: "start",
        }}
      >
        Emission Level Indicator
      </h4>

      <ul style={{ listStyle: "none", padding: "0", margin: "0" }}>
        {Object.entries(data.risk_levels).map(([level, range]) => (
          <li
            key={level}
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "8px",
              gap: "8px",
            }}
          >
            <span
              style={{
                width: "8px",
                height: "73px",
                backgroundColor: colorMapping[level] || "#CCC",
                display: "inline-block",
                borderRadius: "8px",
              }}
            ></span>
            <span
              style={{
                color: "#898989",
                fontSize: "12px",
                flexWrap: "wrap",
              }}
            >
              {range}
            </span>
          </li>
        ))}
      </ul>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
          marginTop: "8px",
        }}
      >
        <span
          style={{
            width: "8px",
            height: "73px",
            backgroundColor: "#FF033E1A",
            display: "inline-block",
            borderRadius: "8px",
          }}
        ></span>
        <span style={{ color: "#898989", fontSize: "12px" }}>No Data</span>
      </div>

      <p
        style={{
          marginTop: "18px",
          fontSize: "14px",
          fontWeight: "500",
          color: "#898989",
          textAlign: "center",
        }}
      >
        Click on a region to view its metrics and analysis
      </p>
    </div>
  );
};

export default MapIndicator;
