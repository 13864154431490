import React,{useState} from 'react'
import "../../css/filter.css"
import AreaAnalysis from "./AreaAnalysis/AreaAnalysis";

const Filter = ({Filterbutton, setfilterbutton, filteroption, setfilteroption}) => {
    // const [areabutton,setareabutton] = useState(false)
    // const [causebutton,setcausebutton] = useState(false)
    // const [filterbutton,setfilterbutton] = useState(Filterbutton);
    // console.log("Filterbutton: ", Filterbutton)

    const areaclickhandle = () => {
        setfilteroption(1)
        setfilterbutton(!Filterbutton)
    }
    const causeclickhandle = () => {
        setfilteroption(2)
        setfilterbutton(!Filterbutton)
    }

    const comparisonclickhandle = () => {
        setfilteroption(5)
        setfilterbutton(!Filterbutton)
    }

    const anayticsclickhandle = () =>{
        setfilteroption(6)
        setfilterbutton(!Filterbutton)
    }
  return (
    <>
    <div className='filter-dialog'>
        {/* <div>
            <div className='analysis'>
                <p className='grey-text'>Analysis</p>
                <p className='close-text'>close all</p>
            </div>
            <div className='grey-line'></div>
        </div> */}
       {/* <div className='filter-category'>
            <ul>
                <li><p className='category-text' onClick={areaclickhandle}>Area Analysis</p></li>
                <li><p className='category-text' onClick={causeclickhandle}>Cause Analysis</p></li>
                <li><p className='grey-text'>Location</p></li>
            </ul>
       </div> */}
       <div>
            {/* <div className='analysis'>
                <p className='grey-text'>Views</p>
            </div> */}
            {/* <div className='grey-line'></div> */}
        </div>
       <div className='filter-category'>
       <ul>
                {/* <li><p className='grey-text'>Current</p></li> */}
                <li><p className='category-text' onClick={anayticsclickhandle}>Analytics</p></li>
                <li><p className='category-text' onClick={comparisonclickhandle}>Frequency Comparison</p></li>
            </ul>
       </div>
       {/* {areabutton ?  <AreaAnalysis/> : ""} */}
    </div>
    </>
  );
}

export default Filter;

