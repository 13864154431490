import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Tadoba from "../src/components/Context/Tadoba";
import Dashboard from "../src/components/Context/Dashboard";
import { Provider } from "react-redux"; // Import Provider from react-redux
import store from "./app/store"; // Import your Redux store

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
  <Dashboard>
  <Tadoba>
    <App />
  </Tadoba>
  </Dashboard>
  </Provider>
  // {/* </React.StrictMode> */}
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
