import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Maps from './Maps';
import MapIndicator from './MapIndicator';

const MapComponent = ({ selectedGas }) => {
  const [mapData, setMapData] = useState(null);
  const [indicatorData, setIndicatorData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMapData = async () => {
      try {
        const token = localStorage.getItem('access_key');
        if (!token) {
          console.error('No access token found.');
          setError('Authentication error. Please log in.');
          return;
        }

        const headers = { Authorization: `Bearer ${token}` };

        const [mapRes, indicatorRes] = await Promise.all([
          axios.get('https://pench-deforestation-be-rxncfgcjfa-el.a.run.app/forest_fire/map-info/', { headers }),
          axios.get(`https://pench-deforestation-be-rxncfgcjfa-el.a.run.app/forest_fire/emission-level-indicator/?gas=${selectedGas}`, { headers }),
        ]);

        setMapData(mapRes.data); // Pass API response data
        setIndicatorData(indicatorRes.data);
      } catch (err) {
        console.error('Error fetching map data:', err);
        setError('Failed to load map data.');
      }
    };

    fetchMapData();
  }, [selectedGas]);

  return (
    <div className="forest-map-left">
      {error && <p className="text-red-500">{error}</p>}
      <MapIndicator data={indicatorData} />
      <Maps mapData={mapData} selectedGas={selectedGas} indicatorData={indicatorData} />
    </div>
    
  );
};

export default MapComponent;
