import React from 'react';

// Map for gases to their standard symbols
const gasSymbols = {
  Methane: 'CH₄',
  Ozone: 'O₃',
  "Carbon Monoxide": 'CO',
  "Sulfur Dioxide": 'SO₂',
  "Nitrogen Dioxide": 'NO₂',
};

// Map for gas colors
const gasColors = {
  'CH₄': "#ED553B", // Orange-Red
  'O₃': "#2576B9", // Green
  'CO': "#173F5F", // Dark Blue
  'SO₂': "#F0C93A", // Light Blue
  'NO₂': "#3CAEA3", // Teal
};

// Function to convert gas names from API to their standardized symbols
const getGasSymbol = (gasName) => {
  return gasSymbols[gasName] || gasName; // Return gas name if no match found
};

const AvgEmissionRate = ({ data,  error }) => {
  if (!data || !data.ghg_rates) return <p>Loading...</p>;

  return (
    <div className="emission-avg-container">
      <h2>Current Average Emission Rates</h2>
      <p className="avg-subtext">
        Current emission rates of GHG are displayed here and remain unaffected
        by the time period filter.
      </p>
      <div className="emission-grid">
        {data.ghg_rates.map((item, index) => {
          const gasSymbol = getGasSymbol(item.gas); // Map the gas name to the standard symbol
          const gasColor = gasColors[gasSymbol] || "#888888"; // Default color if not listed
          const changeColor = item.percent_change > 0 ? 'red' : 'green'; // Red for increase, green for decrease
          const formattedChange = `${item.percent_change > 0 ? "+" : ""}${item.percent_change.toFixed(1)}% since last week`;

          return (
            <div key={index} className="avg-card">
              <div className="avg-emission-icon" style={{ color: gasColor }}>
                <span>{gasSymbol}</span> {/* Display the standardized gas symbol */}
              </div>
              <div className="avg-info">
                <span className="value">
                  {item.avg_measurement.toFixed(2)} {item.unit}
                </span>
                <span className="change" style={{ color: changeColor }}>
                  {formattedChange}
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AvgEmissionRate;
