import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Spin, Modal } from "antd";
import Carousels from "./Carousel";
import { setIsVerified } from "../../features/Userslice";
import tickIcon from '../../assets/SVG/tickIcon.svg';

const OtpPage = () => {
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showPopup, setShowPopup] = useState(false); // Popup state
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (index, value) => {
    if (!/^\d?$/.test(value)) return; // Allow only numbers

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value && index < 5) {
      document.getElementById(`otp-${index + 1}`).focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      document.getElementById(`otp-${index - 1}`).focus();
    }
  };

  const submitHandler = async () => {
    const otpCode = otp.join("");
    if (otpCode.length !== 6) {
      setError("Please enter a valid 6-digit OTP.");
      return;
    }

    setLoading(true);
    setError("");

    const token = localStorage.getItem("access_key");

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BE_URL}/user_profile/verify-2fa-otp/?otp_code=${otpCode}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();

      if (response.ok) {
        dispatch(setIsVerified(true));
        setShowPopup(true);

        setTimeout(() => {
          setShowPopup(false);
          navigate("/section"); // Navigate after modal closes
        }, 2000); 
      } else {
        setError(data.error || "Invalid OTP. Please try again.");
      }
    } catch (error) {
      setError("Something went wrong. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="login-container">
        <div className="left-side">
          <div className="carousel-content">
            <Carousels />
          </div>
        </div>
        <div className="right-side">
          <div style={{ display: "flex", flexDirection: "column", gap: "8px", marginBottom: "32px" }}>
            <div className="Login_Title">
              <span>Verify with Google Authenticator</span>
            </div>
            <div className="Description">
              <p>Enter the 6-digit code from your Google Authenticator app.</p>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "32px" }}>
            <div className="Login_Input">
              <label className="email_icon" htmlFor="login">
                Enter OTP
              </label>
              <div className="OtpContainer">
                {otp.map((digit, index) => (
                  <input
                    key={index}
                    id={`otp-${index}`}
                    type="text"
                    maxLength="1"
                    value={digit}
                    onChange={(e) => handleChange(index, e.target.value)}
                    onKeyDown={(e) => handleKeyDown(index, e)}
                    className="Otp"
                  />
                ))}
              </div>
              {error && <label id="warning">{error}</label>}
            </div>
            <div className="Login_Button">
              <button onClick={submitHandler} disabled={loading}>
                {loading ? <Spin /> : "Verify OTP"}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Success Popup */}
      {showPopup && (
        <div className="popup">
          <img src={tickIcon} className='tick-icon'/>
          <p>Verified Successfully!</p>
        </div>
      )}
    </>
  );
};

export default OtpPage;
