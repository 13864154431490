import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; // Import useNavigate
import BackLogo from '../../../assets/SVG/BackLogo.svg';
import reportIcon from '../../../assets/Emission/Sidebar/logo4.svg';
import download from '../../../assets/SVG/download.svg';
import Delete from '../../../assets/SVG/delete.svg';
import clock from '../../../assets/SVG/clock.svg';
import locationIcon from '../../../assets/SVG/Location.svg';
import user from '../../../assets/SVG/user.svg';
import GhgRate from '../EmissionAnalytics/GHG_Rate';
import EmissionTrend from '../EmissionAnalytics/EmissionTrend';
import EmissionComparison from '../MapAnalytics/EmissionComparison';
import TopContributionRegions from '../MapAnalytics/TopContribution';
import SubRegion from '../MapAnalytics/SubRegion';

const ReportGenerated = () => {
  const navigate = useNavigate(); // Initialize useNavigate hook
  const { state } = useLocation();
  const [selectedLocation, setSelectedLocation] = useState('Delhi');
  const [selectedTime, setSelectedTime] = useState('Past 6 Months');
  const [locations, setLocations] = useState([]);

  // Function to navigate back
  const handleBackClick = () => {
    navigate('/report'); // Navigates to the previous page
  };

  return (
    <div className='report-gen-main'>
        <div className="report-header">
            <img src={BackLogo} alt='Back Icon' className='report-back-icon' onClick={handleBackClick} />

            <div className='report-title-container'>
                <div className='report-card-header-left'>
                    <img src={reportIcon} alt='' />
                     <span className='report-card-title'>{state.title}</span>
                </div>
                <div className="report-gen-row">
                    <span className="report-card-subtitle">Created on: {state.createdAt}</span>
                    <div className="report-card-body-content-Time-name">
                    <img src={clock} alt="Clock Icon" />
                    <span className="report-card-body-content-Time-name-heading">{state.timePeriod}</span>
                    </div>
                </div>
            </div>

            <div className='report-gen-Button-container'>
                <button className='report-card-body-content-Button-container-first'>
                    <img src={download} />
                        Download
                </button>
                <div className='report-card-body-content-Button-container-second'>
                    <img src={Delete} alt="" />
                </div>
            </div>          
        </div>

    <div className="report-card-body-container">
        <div className='report-card-body-content-metrics'>
            <span className='report-card-metrics-subtitle'>Metrics Included</span>
            <div className='report-card-body-content-metrics-content'>
                {state.metrics.map((metric, idx) => (
                    <div key={idx} className='report-card-body-content-metrics-content-body'>
                        <img src={metric.icon} alt='' />
                        <span className='report-card-body-content-title'>{metric.name}</span>
                    </div>
                ))}
            </div>
        </div>

        <div className="report-heading-divider"></div> 

        <div className='report-gen-body-content-Location'>
            <div className='report-card-body-content-Location-content'>
                <span className='report-card-body-content-Location-heading'>Location</span>
                <div className='report-card-body-content-Location-name'>
                    <img src={locationIcon} alt="Location Icon" />
                    <span className='report-card-body-content-Location-name-heading'>{state.location}</span>
                </div>
            </div>
        </div>

        <div className='report-gen-body-content'>
            <img src={user} alt='' />
            <span className='report-card-subtitle'>Created By: </span>
            <span className='report-card-subtitle-name'>{state.createdBy}</span>
        </div>
    </div>

    <div className='report-gen-container'>
        <div className='report-card-body-content-Location-name'>
            <img src={locationIcon} alt="Location Icon" />
            <span className='report-card-body-content-Location-name-heading'>{state.location}</span>
        </div>
        <div className='report-gen-metric-heading'>
            ANALYTICAL METRICS
        </div>
        <GhgRate selectedLocation={selectedLocation} selectedTime={selectedTime} />
        <EmissionTrend selectedLocation={selectedLocation} selectedTime={selectedTime} />
        <EmissionComparison selectedLocation={selectedLocation} />
        <TopContributionRegions selectedLocation={selectedLocation} selectedTime={selectedTime} />
        <div className='report-gen-metric-heading'>
            SUB-REGIONAL ANALYSIS
        </div>
        <SubRegion selectedLocation={selectedLocation} selectedTime={selectedTime} />
    </div>
</div>

        
  );
};

export default ReportGenerated;
