// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./fonts/stylesheet-family-support.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root {\n  --background-color: #F7FBFE;\n  --background-head-color: #F4F7FF;\n  --text-color: #272727;\n  --card-description: #272727;\n  --purple: #4820E4;\n  --blue: #265CFF;\n}\n\n* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  font-style: normal;\n  font-family: \"Inter\" ;\n}\n\n::-webkit-scrollbar {\n  width: 6px;\n  height: 72px;\n}\n\n::-webkit-scrollbar-thumb {\n  background: #D9D9D9;\n  border-radius: 10px;\n}", "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAEA;EACE,2BAA2B;EAC3B,gCAAgC;EAChC,qBAAqB;EACrB,2BAA2B;EAC3B,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;EACtB,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,UAAU;EACV,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,mBAAmB;AACrB","sourcesContent":["@import url(\"../src/fonts/stylesheet-family-support.css\");\n\n:root {\n  --background-color: #F7FBFE;\n  --background-head-color: #F4F7FF;\n  --text-color: #272727;\n  --card-description: #272727;\n  --purple: #4820E4;\n  --blue: #265CFF;\n}\n\n* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  font-style: normal;\n  font-family: \"Inter\" ;\n}\n\n::-webkit-scrollbar {\n  width: 6px;\n  height: 72px;\n}\n\n::-webkit-scrollbar-thumb {\n  background: #D9D9D9;\n  border-radius: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
