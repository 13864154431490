import React, { useState } from "react";
import { Dropdown, Menu } from "antd";
import { DownOutlined } from "@ant-design/icons";
import fireIcon from '../../../assets/ForestFire/ForestMap/fire-icon.svg';

const getRiskClass = (risk) => {
  switch (risk) {
    case "High":
      return "risk-high";
    case "Medium":
      return "risk-medium";
    case "Low":
      return "risk-low";
    default:
      return "";
  }
};
const getChangeIndicator = (percentChange) => {
  if (percentChange > 0) {
    return <span style={{ color: "#F44C4F" }}>▲{percentChange.toFixed(2)}%</span>;
  } else if (percentChange < 0) {
    return <span style={{ color: "#0CAF56" }}>▼-{Math.abs(percentChange).toFixed(2)}%</span>;
  } else {
    return <span>{percentChange.toFixed(2)}%</span>;
  }
};


const PollutionTable = ({ apiData }) => {
  const [selectedRisk, setSelectedRisk] = useState("High");

  const handleMenuClick = (e) => {
    setSelectedRisk(e.key);
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="All levels">All levels</Menu.Item>
      <Menu.Item key="High">High</Menu.Item>
      <Menu.Item key="Medium">Medium</Menu.Item>
      <Menu.Item key="Low">Low</Menu.Item>
    </Menu>
  );

  const filteredData = apiData.filter((row) => {
    return selectedRisk === "All levels" || row.fire_risk === selectedRisk;
  });

  return (
    <div className="pollution-container" >
      <div className="header">
        <div className="filter-container">
          <Dropdown overlay={menu} trigger={["click"]}>
            <button className="filter-button">
              <img src={fireIcon} alt='' />
              {selectedRisk} <DownOutlined style={{marginLeft:'5px'}}/>
            </button>
          </Dropdown>
        </div>
      </div>

      <table className="pollution-table" >
        <thead>
          <tr>
            <th>Region</th>
            <th>Carbon Monoxide (CO)</th>
            <th>Sulfur Dioxide (SO₂)</th>
            <th>Nitrogen Dioxide (NO₂)</th>
            <th>Methane (CH₄)</th>
            <th>Ozone (O₃)</th>
            <th>Forest Fire Risk</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((row, index) => (
            <tr key={index}>
              <td>
                <span className="sub-region-name">{row.region}</span>
                {/* <a href="#" className="map-link">Open in map ↗</a> */}
              </td>
              {['Carbon Monoxide', 'Sulfur Dioxide', 'Nitrogen Dioxide', 'Methane', 'Ozone'].map((gas) => (
                <td key={gas}>
                  {Array.isArray(row.values) ? (
                    <span className='gas-value'>
                      {`${row.values.find(g => g.gas === gas)?.value.toFixed(4) || "-"} ${row.values.find(g => g.gas === gas)?.unit || ""}`} 
                      {row.values.find(g => g.gas === gas)?.percent_change !== null ? (
                        <> {getChangeIndicator(row.values.find(g => g.gas === gas)?.percent_change)}</>
                      ) : ""}
                    </span>
                  ) : "-"}
                </td>
              ))}

              <td className={getRiskClass(row.fire_risk)}>
              {["High", "Medium", "Low"].includes(row.fire_risk) ? (
                <span className="risk">
                  {row.fire_risk}
                  <span className="risk-icon">
                    <img
                      src={fireIcon}
                      alt="Fire Risk Icon"
                      style={{
                        marginLeft: "2px", // Adds spacing between text and icon
                        width: "1.5rem", // Adjust size as needed
                        height: "1.5rem",
                        filter:
                          row.fire_risk === "High"
                            ? "invert(19%) sepia(97%) saturate(7462%) hue-rotate(1deg) brightness(92%) contrast(118%)" // Red
                            : row.fire_risk === "Medium"
                            ? "invert(85%) sepia(41%) saturate(511%) hue-rotate(1deg) brightness(98%) contrast(97%)" // Yellow
                            : "invert(42%) sepia(95%) saturate(653%) hue-rotate(93deg) brightness(95%) contrast(88%)", // Green
                      }}
                    />
                  </span>
                </span>
              ) : (
                row.fire_risk
              )}
            </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PollutionTable;
