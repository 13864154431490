import React, { useEffect, useState } from 'react';
import axios from 'axios';import { useNavigate } from 'react-router-dom';
import mainLogo from '../../assets/ForestFire/Navbar/forestIcon.jpg';
import valiance from '../../assets/Emission/Navbar/valiance.svg';
import profilePic from '../../assets/Emission/Navbar/profile-pic.jpg';
import { FiLogOut } from 'react-icons/fi'; // Importing logout icon

const NavbarForest = () => {
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState(null);
  const [activeTab, setActiveTab] = useState('/dashboard/forest-fire'); 

  const handleTabClick = (path) => {
    setActiveTab(path); 
    navigate(path); 
  };
  
  const handleLogoClick = () => {
    navigate('/section');  
  };

  const handleLogout = () => {
    if (localStorage.getItem("access_key") !== null) {
      localStorage.removeItem("access_key")
      localStorage.clear()
      navigate("/")
      window.location.reload();
    }
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BE_URL}/user_profile/profile/`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('access_key')}`,
            },
          }
        );
        setUserDetails(response.data); // Set the fetched user details
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    fetchUserDetails();
  }, []);

  return (
    <div className='forest-navbar-main'>
       <img src={mainLogo} alt="logo" className="forest-navbar-logo" onClick={handleLogoClick}/>
       <div className='forest-navbar-wrapper'>
          <div className="forest-logo-column-1"  onClick={handleLogoClick}>
            <p>Forest Fire</p>
            <img src={valiance} alt="Valiance" className="valiance" />
          </div>
        <div className="forest-navbar-tabs">
          {/* <button
            className={`forest-tab ${activeTab === '/forestdashboard' ? 'forest-tab-active' : ''}`}
            onClick={() => handleTabClick('/forestdashboard')}
          >
            Dashboard
          </button> */}
          <button
            className={`forest-tab ${activeTab === '/dashboard/forest-fire' ? 'forest-tab-active' : ''}`}
            onClick={() => handleTabClick('/dashboard/forest-fire')}
          >
            Map View
          </button>
          {/* <button
            className={`forest-tab ${activeTab === '/fire-details' ? 'forest-tab-active' : ''}`}
            onClick={() => handleTabClick('/fire-details')}
          >
            Fire Details
          </button> */}
        </div>


         {/* Profile */}
         <div className="profile-wrapper">
          <button className="logout-button" onClick={handleLogout}>
            <FiLogOut className="logout-icon" />
            Logout
          </button>
            <img src={profilePic} alt="Profile Picture" className="profile-pic" />
            <div className="profile-details">
              <p className="profile-name"> 
                Hi {userDetails ? `${userDetails.first_name} ! ` : 'Admin'}
              </p>
            </div>
          </div>
        </div>
    </div>
  )
}

export default NavbarForest;

