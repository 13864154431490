import React, { useContext, useEffect, useState } from "react";
import "../../css/AppPage.css"; // Import the CSS file for styling
import { ReactComponent as AppNameLogo } from "../../assets/SVG/forest_img.svg";
import { ReactComponent as Login3 } from "../../assets/SVG/login3.svg";
import Login1 from "../../assets/SVG/login1.svg";
import Login2 from "../../assets/SVG/login2.svg";
import Valiancelogo from "../../assets/Images/logo 1.png";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import TadobaContext from "../Context/TadobaContext";
import OtpPage from "./OtpPage";
import { Button, Spin } from "antd";
import { makeRequest } from "../../api";
import Carousels from "./Carousel";
import Forgot_Password from "./Forgot_Password";

const AppPage = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState("");
  const [type, setType] = useState("password");
  const api = useContext(TadobaContext);
  const [OtpSent, setOtpSent] = useState(false);
  const [userData, setUserData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [email, setEmail] = useState("");

  function ValidateEmail(input) {
    var validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (input.match(validRegex)) {
      return true;
    } else {
      return false;
    }
  }

  const showPasswordHandler = (event) => {
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  };

  const credentialChangeHandler = (event) => {
    setError("");
    setMessage("");
    if (event.target.name === "email") {
      setEmail(event.target.value);
    }
    if (event.target.name === "password") {
      if (email === "") {
        setError("email");
        setErrorMessage("Email Id Required");
      } else if (ValidateEmail(email) === false) {
        setError("email");
        setErrorMessage("Invalid Email Id");
      }
      setPassword(event.target.value);
    }
  };

  useEffect(() => {
    if (message?.includes("Enter a valid email address")) {
      setError("email");
      setErrorMessage("Invalid Email Id");
    } else if (message?.includes("user")) {
      setErrorMessage("User not available");
      setError("email");
    } else if (message?.includes("Incorrect")) {
      setError("password");
      setErrorMessage("Invalid Password");
    } else if (
      message?.includes(
        "Password must contain at least 1 letter, 1 number, 1 special character, and be at least 10 characters long."
      )
    ) {
      setErrorMessage(
        "Password must contain at least 1 letter, 1 number, 1 special character, and be at least 10 characters long."
      );
      setError("password");
    } else if (
      message?.includes(
        "Account temporary is blocked on this IP address. Please contact administrator"
      )
    ) {
      setDisabled(true);
      // setErrorMessage(
      //   "Account temporary is blocked on this IP address. Please contact administrator"
      // );
      // toast.warning(
      //   "Account temporary is blocked on this IP address. Please contact administrator",
      //   warningToaster
      // );
    } else if (
      message?.includes("OTP is not valid") ||
      message?.includes("otp value is required") ||
      message?.includes("otp_attempts")
    ) {
      setErrorMessage("Invalid Otp");
      setError("Otp");
    } else {
      setError("");
    }
  }, [message]);

  const forgotEventHandler = () => {
    navigate("/forgot_password");
  };

  const loginRequest = async (loginData) => {
    setLoading(true);
    // console.log(loginData);
    try {
      const result = await makeRequest(
        "post",
        `/user_profile/login_user/`,
        loginData
      );
      setUserData(result.data.data);
      // console.log(result.data.data);
      setOtpSent(true);
    } catch (error) {
      // console.log(error.response.data.non_field_errors[0]);
      if (error?.response?.data?.non_field_errors?.[0] !== undefined) {
        // console.log("undefined");
        setMessage(error.response.data.non_field_errors[0]);
      } else if (error?.response?.data?.email?.[0] !== undefined)
        setMessage(error.response.data.email[0]);
    } finally {
      setLoading(false);
    }
  };

  const OtpSubmitHandler = async (value) => {
    setLoading(true);
    try {
      const result = await makeRequest(
        "post",
        `/user_profile/verify_otp_email_sms/`,
        value
      );
      // console.log("otp value");
      // console.log(value);
      // console.log("otp result");
      // console.log(result.data);
      localStorage.setItem("access_key", result.data.access);
      localStorage.setItem("refresh", result.data.refresh);
      // navigate to new page after successful login 
      navigate("/section");
      // navigate("/dashboard");
      window.location.reload();
    } catch (error) {
      setError("Otp");
      setMessage(error.response.data.error);
    } finally {
      setLoading(false);
    }
  };

  const LoginHandler = (e) => {
    e.preventDefault();
    if (email === "") {
      setErrorMessage("The field is required");
      setError("email");
    } else if (password === "") {
      setErrorMessage("The field is required");
      setError("password");
    } else {
      const loginData = {
        email: email,
        password: password,
      };
      // loginRequest(encryptPayload(loginData));
      loginRequest(loginData);
    }
  };

  const passwordFocusHandler = () => {
    if (email === "") {
      setErrorMessage("The field is required");
      setError("email");
    } else if (ValidateEmail(email) === false) {
      setError("email");
      setErrorMessage("Invalid Email Id");
    }
  };

  const encryptAES = (plaintext) => {
    const secretKey = "RUFqlY38xUWND5Yt";
    const key = CryptoJS.enc.Utf8.parse(secretKey);
    const iv = CryptoJS.lib.WordArray.random(16);
    const encrypted = CryptoJS.AES.encrypt(plaintext, key, {
      iv: iv,
      padding: CryptoJS.pad.Pkcs7,
    });
    const combined = CryptoJS.lib.WordArray.create();
    combined.concat(iv).concat(encrypted.ciphertext);
    return combined.toString(CryptoJS.enc.Base64);
  };

  const encryptPayload = (payload, secretKey) => {
    const encryptedPayload = {};
    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        encryptedPayload[key] = encryptAES(payload[key]);
      }
    }
    return encryptedPayload;
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (email === "") {
        setErrorMessage("The field is required");
        setError("email");
      } else if (password === "") {
        setErrorMessage("The field is required");
        setError("password");
      } else {
        const loginData = {
          email: email,
          password: password,
        };
        // loginRequest(encryptPayload(loginData));
        loginRequest(loginData);
      }
    }
  };

  return (
    <>
      {!OtpSent ? (
        <>
          <div className="login">
            <div className="company-container">
              {/* <div className="application-name-img"> */}
              {/* <AppNameLogo /> */}
              <img
                className="forest_img"
                src={require("../../assets/Images/pench_logo.jpeg")}
              />
              {/* </div> */}
              <div className="Application-name">
                <p>Deforestation Monitoring</p>
                <ul>
                  <li>
                    <span>Powered By</span>
                  </li>
                  <li>
                    <img src={Valiancelogo}></img>
                    
                  </li>
               
                </ul>
              </div>
            </div>
            <div className="login-title">
              <span>Hey there!</span>
              <p>Please login using your credentials.</p>
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "32px" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "24px",
                }}
              >
                <div className="Login_Input">
                  <label className="email_icon" htmlFor="login">
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    onKeyDown={handleKeyDown}
                    placeholder="Enter your email"
                    className={error === "email" ? "input_warning" : ""}
                    value={email}
                    autoFocus="autofocus"
                    onChange={credentialChangeHandler}
                    id="login"
                  />
                  {error === "email" ? (
                    <label id="warning">{errorMessage}</label>
                  ) : (
                    ""
                  )}
                </div>
                <div className="Login_Input">
                  <label className="password_icon" htmlFor="password">
                    Password
                  </label>
                  <div className="password">
                    <input
                      type={type}
                      name="password"
                      onKeyDown={handleKeyDown}
                      onFocus={passwordFocusHandler}
                      className={error === "password" ? "input_warning" : ""}
                      value={password}
                      onChange={credentialChangeHandler}
                      placeholder="Enter your password"
                      id="password"
                    />
                    {type === "text" ? (
                      <img
                        src={require("../../assets/Icons/eye.png")}
                        onClick={showPasswordHandler}
                        alt=""
                      />
                    ) : (
                      <img
                        onClick={showPasswordHandler}
                        src={require("../../assets/Icons/eye-off.png")}
                        alt=""
                      />
                    )}
                  </div>
                  {error === "password" ? (
                    <label id="warning">{errorMessage}</label>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="remember-forgot">
                {/* <label>
                <input type="checkbox" defaultChecked/>
                Keep me signed in
              </label> */}
                <span onClick={forgotEventHandler}>Forgot Password ?</span>
              </div>
              <div
                className={
                  !disabled
                    ? "Login_Button"
                    : "Login_Button login_button_disabled"
                }
              >
                <Spin spinning={loading}>
                  {" "}
                  <button disabled={disabled} onClick={LoginHandler}>
                    Log In
                  </button>
                </Spin>
              </div>
            </div>
          </div>
        </>
      ) : (
        // <Forgot_Password/>
        <OtpPage
          email={email}
          userData={userData}
          message={errorMessage}
          loading={loading}
          disabled={disabled}
          setMessage={setMessage}
          setError={setError}
          error={error}
          errorMessage={errorMessage}
          OtpSubmitHandler={OtpSubmitHandler}
        />
      )}
    </>
  );
};

export default AppPage;
