import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../features/Userslice";

const store = configureStore({
    reducer: {
        user: userReducer,
    }
});

export default store;
