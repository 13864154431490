import React, { useState, useEffect, useCallback, useContext } from "react";
import "../../css/dashboardComparison.css";
// import { Space, Divider, Col, Row } from "antd";
import { ReactComponent as ForestNameLogo } from "../../assets/SVG/forest-name.svg";
import Valiancelogo from "../../assets/Images/logo 1.png";
import { useLocation, useNavigate } from "react-router-dom";
import AreaAnalysis from "./AreaAnalysis/AreaAnalysis";
import Filter from "../../components/Dashboard/Filter";
import FloatingButton from "../FloatingButton";
import "../../css/FloatingButton.css";
import DashboardMap from "./DashboardMap";
import dayjs from "dayjs";
import { Button, Modal, DatePicker, Space, Select, TimePicker } from "antd";
import TadobaContext from "../Context/TadobaContext";
import DashboardContext from "../Context/DashboardContext";

const displayFormat = "DD-MM-YYYY";
const internalFormat = "YYYY-MM-DD";
const { Option } = Select;

const Dashboard2 = ({mapCoords,setMapCoords,apiAllResponse,isLoading,apiResponse,setToDate,setFromDate,setSelectedCause,setfilteroption,fromDate,toDate,causes}) => {
  // const [isLoading, setIsLoading] = useState(false);
  // const [imageUrl, setImageUrl] = useState("");
  const api = useContext(TadobaContext);
  const [selectedRange, setSelectedRange] = useState(api.range);
  // const causes = ["All", "Farming", "Building", "Lake"];


  const causesList = causes.map(item => item.cause_name);

  causesList.push("All");

  const [zoom, setZoom] = useState(10.8);
  const [center, setCenter] = useState({ lat: 21.572004, lng: 79.19164 });
  const handleCenterChanged = (newCenter) => {
    // if (center.lat !== newCenter.lat || center.lng !== newCenter.lng) {
    //   setCenter(newCenter);
    // }
  };

  const {type, setType} = useContext(DashboardContext);

  const handleZoomChanged = (newZoom) => {
    if (zoom !== newZoom) {
      setZoom(newZoom);
    }
  };

  const handleDateChangeTo = (date, dateString) => {
    
    // console.log("date is", date);
    // console.log("dateString is", dateString);
    const formattedDate = date ? dayjs(date).format(internalFormat) : "";
    // console.log("formattedDate is", formattedDate);
    setToDate(formattedDate);
    api.setToDate(formattedDate);
    // console.log(selectedRange);
  };

  
  const handleDateChangeFrom = (date, dateString) => {
    // console.log("date is", date);
    // console.log("dateString is", dateString);
    const formattedDate = date ? dayjs(date).format(internalFormat) : "";
    // console.log("formattedDate is", formattedDate);
    setFromDate(formattedDate);
    api.setFromDate(formattedDate);
    // console.log(selectedRange);
  };

  useEffect(() => {
    handleRangeChange(selectedRange);
  }, [toDate]);

  const getFormattedDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const subtractMonths = (date, months) => {
    const newDate = new Date(date);

    const totalMonths =
      newDate.getFullYear() * 12 + newDate.getMonth() - months;

    const newYear = Math.floor(totalMonths / 12);
    const newMonth = totalMonths % 12;

    newDate.setFullYear(newYear);
    newDate.setMonth(newMonth);

    return newDate;
  };

  const handleCauseChange = (value) => {
    if (value === "All") {
      setSelectedCause("");
    } else setSelectedCause(value);
  };

  const handleRangeChange = (value) => {
    // console.log("value is", value);
    const date = toDate;
    // console.log("date is", date);
    setSelectedRange(value);
    // const presentDate = getFormattedDate(date);
    let rangeDate;
    if (value === "Past 3 months") {
      rangeDate = getFormattedDate(subtractMonths(date, 3));
    } else if (value === "Past 6 months") {
      rangeDate = getFormattedDate(subtractMonths(date, 6));
    } else if (value === "Past 9 months") {
      rangeDate = getFormattedDate(subtractMonths(date, 9));
    } else if (value === "Past 12 months") {
      rangeDate = getFormattedDate(subtractMonths(date, 12));
    } else if (value === "Past 15 months") {
      rangeDate = getFormattedDate(subtractMonths(date, 15));
    } else if (value === "Past 18 months") {
      rangeDate = getFormattedDate(subtractMonths(date, 18));
    } else if (value === "Past 21 months") {
      rangeDate = getFormattedDate(subtractMonths(date, 21));
    } else if (value === "Past 24 months") {
      rangeDate = getFormattedDate(subtractMonths(date, 24));
    } else if (value === "Past 27 months") {
      rangeDate = getFormattedDate(subtractMonths(date, 27));
    } else if (value === "Past 30 months") {
      rangeDate = getFormattedDate(subtractMonths(date, 30));
    } else if (value === "Past 33 months") {
      rangeDate = getFormattedDate(subtractMonths(date, 33));
    } else if (value === "Past 36 months") {
      rangeDate = getFormattedDate(subtractMonths(date, 36));
    }
    // console.log("range date is", rangeDate);
    setFromDate(rangeDate);
    api.setFromDate(rangeDate);
    api.setRange(value);
    // setToDate(toDate)
    // console.log("fromdate in frequncymodal is", fromDate)
    // console.log(toDate);
  };

  const ranges = [
    {
      value: "1",
      label: "Past 3 months",
    },
    {
      value: "2",
      label: "Past 6 months",
    },
    {
      value: "3",
      label: "Past 9 months",
    },
    {
      value: "4",
      label: "Past 12 months",
    },
    {
      value: "5",
      label: "Past 15 months",
    },
    {
      value: "6",
      label: "Past 18 months",
    },
    {
      value: "7",
      label: "Past 21 months",
    },
    {
      value: "8",
      label: "Past 24 months",
    },
    {
      value: "9",
      label: "Past 27 months",
    },
    {
      value: "10",
      label: "Past 30 months",
    },
    {
      value: "11",
      label: "Past 33 months",
    },
    {
      value: "12",
      label: "Past 36 months",
    },
  ];
  return (
    <div style={{ width: "100%" }}>
      <header
        style={{
          boxShadow: "rgba(0, 0, 0, 0.31) 0px 1px 10px",
          borderRadius: "13px",
        }}
        className="header"
      >
        <div style={{ padding: "2vh 1vw" }} className="header_left">
          <h3
            style={{ fontFamily: "inter" }}
            className="DashboardComparison_h3"
          >
            Overall Analysis{" "}
            <p
              style={{ fontSize: "1.8vh", fontWeight: "400", color: "#737373" }}
            >
              This page presents analytical charts on deforestation increase and
              analyzes the causes behind it.
            </p>
          </h3>
        </div>
      
        <div style={{ padding: "2vh 1vw" }} className="right_div">
        
          <ul className="area_header_right_list">
            
            <li style={{marginRight:"-5rem"}} className="area_header_right_text">
            <p style={{position:"relative", width:"8rem",left:"7rem"}}>Select To Date</p>
              <Space
                style={{ marginLeft: "7rem" }}
                direction="vertical"
                size={12}
              >
                <DatePicker
                  style={{ backgroundColor: "#E7F0FE", width: "12rem" }}
                  defaultValue={dayjs(toDate, internalFormat)}
                  placeholder="Select date"
                  format={displayFormat}
                  onChange={handleDateChangeTo}
                  picker="month"
                />
              </Space>
            </li>

            {type === "Deforestation" ? (<>
              <Select
                style={{ paddingLeft:"6rem"}}
                placeholder="Select time range"
                onChange={handleRangeChange}
                defaultValue={selectedRange}
                value={selectedRange}>
                  {ranges.map((range, index) => (
                  <Option key={index} value={range.label}>
                    {range.label}
                  </Option>
                ))}
                </Select>
                </>) : (
                <>
                <li className="area_header_right_text">
            <p style={{position:"relative", width:"9rem", left:"7rem"}}>Select From Date</p>
            <Space style={{ marginLeft: "7rem" }}
                direction="vertical"
                size={12}>
                <DatePicker
                  style={{ backgroundColor: "#E7F0FE", width: "12rem" }}
                  defaultValue={dayjs(toDate, internalFormat)}
                  placeholder="Select date"
                  format={displayFormat}
                  onChange={handleDateChangeFrom}
                  picker="month"
                />
              </Space>
            </li>
            </>
          )}
            <li className="area_header_right_text">
              <Select placeholder="select a cause" onChange={handleCauseChange}>
                {causesList.map((cause, index) => (
                  <Option key={index} value={cause}>
                    {cause}
                  </Option>
                ))}
              </Select>
            </li>
          </ul>
        </div>
      </header>

      <main className="analysis-content">
        <AreaAnalysis
          apiResponse={apiResponse}
          setToDate={setToDate}
          setFromDate={setFromDate}
          setSelectedCause={setSelectedCause}
          name={"Area"}
          chart={"linechart"}
          fromDate={fromDate}
          toDate={toDate}
          // setfilteroption={setfilteroption}
        />

        <AreaAnalysis
          // apiResponse={apiAllResponse}
          apiResponse={apiResponse}
          setToDate={setToDate}
          setFromDate={setFromDate}
          setSelectedCause={setSelectedCause}
          name={"Cause"}
          chart={"piechart"}
          fromDate={fromDate}
          toDate={toDate}
          // setfilteroption={setfilteroption}
        />
      </main>
      <DashboardMap
        mapCoords={mapCoords}
        setMapCoords={setMapCoords}
        isLoading={isLoading}
        center={center}
        zoom={zoom}
        onCenterChanged={handleCenterChanged}
        onZoomChanged={handleZoomChanged}
      />
    </div>
  );
};

export default Dashboard2;
