import React, { Component } from "react";
import Slider from "react-slick";
import Login1 from "../../assets/SVG/login1.svg";
import Login2 from "../../assets/SVG/login2.svg";
import Login3 from "../../assets/SVG/login3.svg";
import "../../css/Carousel.css";
import { Carousel } from 'antd';

function Item(props) {
  return (
      <div className="content">
            <img src={props.src} alt="Deforestation" className="image"/>
            <div className="card-content">
              <h2>{props.heading}</h2>
              <p>
                {props.para}
              </p>
            </div>
      </div>
  );
}

function Carousels() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  return (
    <div className="slider-container">
      <Carousel autoplay>
         <Item src={Login1} heading="Monitor Deforestation with Precision" para="Your go-to platform for tracking deforestation and analyzing its causes. Dive deep into data to understand the changes in forest cover over time and learn how different factors contribute to deforestation."/>
         <Item src={Login2} heading="Historical Comparison" para="Compare current forest cover with past records to see how the landscape has changed over the years. Area analysis over time will be shown in a clear line chart format. This will reveal trends such as deforestation over the specified period"/>
         <Item src={Login3} heading="Cause & Area Analysis" para="Understand the primary causes of deforestation in each region, whether it’s agriculture, logging, urban development, or natural causes. The causes will be displayed in an easy-to-understand pie chart format."/>
      </Carousel>
    </div>
  );
}

export default Carousels;