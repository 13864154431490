import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Dashboard from './container/Dashboard';
import Section from './container/Section';
import EmissionPage from './container/EmissionPage';
import MapAnalytics from './container/MapPage';
import Report from './container/ReportPage';
import Profile from './container/ProfilePage';
import ForestFire from './container/ForestFirePage';
import ReportDetails from './components/Emission/Report/ReportDetails';

const Services = () => {
  return (
    <Routes>
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/section" element={<Section />} />
      <Route path="/dashboard/emission" element={<EmissionPage />} />
      <Route path="/map-wise" element={<MapAnalytics />} />
      <Route path="/report" element={<Report />} />
      <Route path="/report-details" element={<ReportDetails />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/dashboard/forest-fire" element={<ForestFire />} />
    </Routes>
  );
};

export default Services;
