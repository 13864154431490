import React from "react";
import { IoLocationOutline } from "react-icons/io5";
import fireIcon from "../../../assets/ForestFire/ForestMap/fire-icon.svg";

const riskColors = {
  Low: "#0CAF56", // Green
  "Low Medium": "#0CAF56", // Green
  Medium: "#FCCC0A", // Yellow
  "Medium High": "#FCCC0A", // Yellow
  High: "#F44C4F", // Red
};

// Mapping gas names to their chemical symbols
const gasSymbols = {
  "Carbon Monoxide": "CO",
  "Sulfur Dioxide": "SO₂",
  "Nitrogen Dioxide": "NO₂",
  Methane: "CH₄",
  Ozone: "O₃",
};

const MapDetails = ({ selectedState, mapData }) => {
  if (!mapData || !selectedState || !mapData.map_info[selectedState]) {
    return <p>No data available</p>;
  }

  const stateData = mapData.map_info[selectedState];
  const { values, individual_fire_risk, overall_fire_risk } = stateData;
  const fireRiskColor = riskColors[overall_fire_risk] || "#F44C4F"; // Default to red if not found

  return (
    <div className="map-details-container" style={{ width: "350px", padding: "16px", background: "#fff", borderRadius: "8px", boxShadow: "0px 4px 6px rgba(0,0,0,0.1)" }}>
      {/* Header */}
      <div style={{ display: "flex", alignItems: "center", fontWeight: "500", fontSize: "16px", color: "#0E181C" }}>
        <IoLocationOutline size={20} style={{ marginRight: "8px" }} />
        {selectedState}
      </div>

      {/* Fire Risk Section */}
      <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", marginTop: "16px" }}>
        <p style={{ fontWeight: "500", fontSize: "14px", color: "#898989" }}>Fire risk</p>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
          <span style={{ fontWeight: "600", fontSize: "16px", color: fireRiskColor }}>{overall_fire_risk}</span>
          <img
            src={fireIcon}
            alt="Fire Risk Icon"
            style={{
              width: "2.5rem",
              height: "2.5rem",
              marginLeft: "auto",
              marginTop: "-1rem",
              filter:
                fireRiskColor === "#0CAF56"
                  ? "invert(37%) sepia(100%) saturate(439%) hue-rotate(91deg) brightness(92%) contrast(92%)"
                  : fireRiskColor === "#FCCC0A"
                  ? "invert(80%) sepia(92%) saturate(200%) hue-rotate(2deg) brightness(100%) contrast(100%)"
                  : fireRiskColor === "#F44C4F"
                  ? "invert(42%) sepia(97%) saturate(558%) hue-rotate(-9deg) brightness(99%) contrast(98%)"
                  : "none",
            }}
          />
        </div>
      </div>

      {/* Gas Information Section */}
      <div style={{ display: "flex", flexDirection: "column", gap: "12px", marginTop: "16px" }}>
        {Array.isArray(values) &&
          values.map((item, index) => {
            const gasSymbol = gasSymbols[item.gas] || item.gas;
            const riskLevel = individual_fire_risk[item.gas] || "Low";
            const riskColor = riskColors[riskLevel] || "#666";

            return (
              <div key={index} style={{ display: "flex", flexDirection: "column" }}>
                <p style={{ fontSize: "14px", fontWeight: "500", margin: "0", color: "#898989" }}>{gasSymbol} Level</p>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                  <span style={{ fontSize: "16px", fontWeight: "500", color: riskColor }}>
                    {parseFloat(item.value).toFixed(4)} {item.unit}
                  </span>
                  <span
                    style={{
                      backgroundColor: riskColor,
                      color: "#fff",
                      fontSize: "12px",
                      fontWeight: "500",
                      padding: "2px 8px",
                      borderRadius: "16px",
                    }}
                  >
                    {riskLevel}
                  </span>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default MapDetails;
