import React, { useEffect, useState } from "react";
// import TadobaContext from "./TadobaContext";
import DashboardContext from "./DashboardContext";

const DashboardProvider = ({ children }) => {
  // const [type, setType] = useState();
  const [type, setType] = useState(()=>{
    return localStorage.getItem('type') || 'Deforestation'; // Default to Deforestation 
  })

  useEffect(()=>{
    localStorage.setItem('type',type);
  },[type])

  return (
    <DashboardContext.Provider value={{type,setType}}>
      {children}
    </DashboardContext.Provider>
  );
};

export default DashboardProvider;
