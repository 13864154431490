import React from "react";
import Forgot_Password from "../components/Login/Forgot_Password";
import Login_Details from "../components/Login/Login_Details";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Reset_Password from "../components/Login/Reset_Password";
// import "../css/login.css"
import "../css/AppPage.css"
import Login1 from "../assets/SVG/login1.svg";
import Login2 from "../assets/SVG/login2.svg";
import LoginDetails from "../components/Login/LoginDetails";
import Carousels from "../components/Login/Carousel"
// import Reset_Password from "../components/Login/Reset_Password";
const Login = ({ setLogin }) => {
  const [email, setEmail] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const handlePopState = (event) => {
      navigate('/');
    };

    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate]);

  return (
    <div className="login-container">
      <div className="left-side">
        <div className="carousel-content">
            <Carousels/>
        </div>
      </div>
      <div className="right-side">
        <Routes>
          <Route
            path="/*"
            element={
              <LoginDetails
                email={email}
                setEmail={setEmail}
                setLogin={setLogin}
              />
            }
          />
          <Route
            path="/forgot_password"
            element={<Forgot_Password email={email} setEmail={setEmail} />}
          />
          <Route
            path="/reset_password/:token/:uid"
            element={<Reset_Password />}
          />
        </Routes>
      </div>
    </div>
  );
};

export default Login;
