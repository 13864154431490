import React from 'react';
import '../css/FloatingButton.css'; // Import the CSS file for styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

const FloatingButton = ({ onLogout }) => {
    return (
        <button className="floating-button" onClick={onLogout}>
            <FontAwesomeIcon icon={faSignOutAlt} />
            <p>Logout</p>
            
        </button>
    );
};

export default FloatingButton;