import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import arrow from '../../../assets/SVG/arrow.svg';
import userIcon from '../../../assets/SVG/user.svg';
import Delete from '../../../assets/SVG/delete.svg';
import clock from '../../../assets/SVG/clock.svg';
import download from '../../../assets/SVG/download.svg';
import bubble from '../../../assets/SVG/bubble.svg';
import numericIcon from '../../../assets/SVG/numeric.svg';
import piechartIcon from '../../../assets/SVG/pieChart.svg';
import lineChartIcon from '../../../assets/SVG/lineChart.svg';
import locationIcon from '../../../assets/SVG/Location.svg';
import reportIcon from '../../../assets/Emission/Sidebar/logo4.svg';

const API_URL = "https://pench-deforestation-be-rxncfgcjfa-el.a.run.app/emissions/get-reports/";
const DELETE_API_URL = "https://pench-deforestation-be-rxncfgcjfa-el.a.run.app/emissions/delete-report/";

const metricIcons = {
  heat_map: bubble,
  bubble_map: bubble,
  include_averages: numericIcon,
  include_comparisons: piechartIcon,
  include_trends: lineChartIcon
};

const ReportCard = () => {
  const navigate = useNavigate();
  const [reports, setReports] = useState([]);
  const accessToken = localStorage.getItem('access_key');

  useEffect(() => {
    const fetchReports = async () => {
      try {
        const response = await axios.get(API_URL, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          }
        });

        const formattedData = response.data.reports.map(report => ({
          id: report.id,
          title: report.title,
          createdAt: new Date(report.created_on).toLocaleString(),
          createdBy: report.author,
          location: report.location,
          timePeriod: report.timeframe,
          metrics: [
            report.map_type ? { name: report.map_type, icon: metricIcons[report.map_type] } : null,
            report.include_averages ? { name: "GHG Average Emission", icon: metricIcons.include_averages } : null,
            report.include_comparisons ? { name: "Emission Comparison", icon: metricIcons.include_comparisons } : null,
            report.include_trends ? { name: "Emission Trends", icon: metricIcons.include_trends } : null
          ].filter(Boolean)
        }));

        setReports(formattedData);
      } catch (error) {
        console.error("Error fetching reports:", error);
      }
    };

    if (accessToken) {
      fetchReports();
    } else {
      console.error("Access token not found");
    }
  }, [accessToken]);

  const handleDelete = async (title) => {
    try {
      await axios.delete(`${DELETE_API_URL}?title=${encodeURIComponent(title)}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        }
      });

      setReports(reports.filter(report => report.title !== title));
    } catch (error) {
      console.error("Error deleting report:", error);
    }
  };

  const handleDownload = (card) => {
    const pdf = new jsPDF();
    pdf.setFontSize(16);
    pdf.text("Report Details", 20, 20);
    
    pdf.setFontSize(12);
    pdf.text(`Title: ${card.title}`, 20, 30);
    pdf.text(`Created on: ${card.createdAt}`, 20, 40);
    pdf.text(`Created by: ${card.createdBy}`, 20, 50);
    pdf.text(`Location: ${card.location}`, 20, 60);
    pdf.text(`Time Period: ${card.timePeriod}`, 20, 70);

    if (card.metrics.length > 0) {
      pdf.text("Metrics Included:", 20, 80);
      card.metrics.forEach((metric, index) => {
        pdf.text(`- ${metric.name}`, 25, 90 + index * 10);
      });
    }

    pdf.save(`${card.title}.pdf`);
  };

  return (
    <div className="report-card-container">
      {reports.length === 0 ? <p>No reports available</p> : null}
      {reports.map((card, index) => (
        <div key={index} className="report-card-content">
          <div className="report-card-header">
            <div className="report-card-header-left">
              <img src={reportIcon} alt="Report Icon" />
              <span className="report-card-title">{card.title}</span>
            </div>
            <img 
              src={arrow} 
              alt="Navigate" 
              style={{ cursor: "pointer" }} 
              onClick={() => navigate('/report-details', { state: card })} 
            />
          </div>
          <div className="report-card-body">
            <span className="report-card-subtitle">Created on: {card.createdAt}</span>
            <div className="report-card-body-content">
              <img src={userIcon} alt="User Icon" />
              <span className="report-card-subtitle">Created by: </span>
              <span className="report-card-subtitle-name">{card.createdBy}</span>
            </div>
          </div>
          <div className="report-card-body-content-metrics">
            <span className="report-card-metrics-subtitle">Metrics Included</span>
            <div className="report-card-body-content-metrics-content">
              {card.metrics.map((metric, idx) => (
                <div key={idx} className="report-card-body-content-metrics-content-body">
                  <img src={metric.icon} alt="Metric Icon" />
                  <span className="report-card-body-content-title">{metric.name}</span>
                </div>
              ))}
            </div>
          </div>
          <div className="report-card-body-content-Location">
            <div className="report-card-body-content-Location-content">
              <span className="report-card-body-content-Location-heading">Location</span>
              <div className="report-card-body-content-Location-name">
                <img src={locationIcon} alt="Location Icon" />
                <span className="report-card-body-content-Location-name-heading">{card.location}</span>
              </div>
            </div>
          </div>
          <div className="report-card-body-content-Location">
            <div className="report-card-body-content-Location-content">
              <span className="report-card-body-content-Location-heading">Time Period</span>
              <div className="report-card-body-content-Time-name">
                <img src={clock} alt="Clock Icon" />
                <span className="report-card-body-content-Time-name-heading">{card.timePeriod}</span>
              </div>
            </div>
          </div>
          <div className="report-card-body-content-Button-container">
            <button 
              className="report-card-body-content-Button-container-first"
              onClick={() => handleDownload(card)}
            >
              <img src={download} alt="Download Icon" />
              Download
            </button>
            <div 
              className="report-card-body-content-Button-container-second"
              onClick={() => handleDelete(card.title)}
              style={{ cursor: "pointer" }}
            >
              <img src={Delete} alt="Delete Icon" />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ReportCard;
