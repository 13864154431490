import React, { useState, useEffect } from "react";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import { feature } from "topojson-client";
import indiaTopoJson from "./india-state.json"; // Ensure correct path
import { Tooltip } from "react-tooltip";
import MapDetails from './MapDetails';

const colorMapping = {
  low: "#173F5F33",
  low_medium: "#173F5F66",
  medium: "#173F5F99",
  medium_high: "#173F5FCC",
  high: "#173F5F",
};

// Function to normalize state names to lowercase
const normalize = (name) => name?.trim().toLowerCase();

const Maps = ({ mapData, indicatorData, selectedGas }) => {
  const [stateName, setStateName] = useState();
  const [districtName, setDistrictName] = useState();
  const [selectedState, setSelectedState] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [topoJsonData, setTopoJsonData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    try {
      console.log("TopoJSON Data:", indiaTopoJson); // Debugging
      if (indiaTopoJson.objects && indiaTopoJson.objects.states) {
        const geoJsonData = feature(indiaTopoJson, indiaTopoJson.objects.states);
        console.log("Converted GeoJSON:", geoJsonData); // Debugging
        setTopoJsonData(geoJsonData);
      } else {
        console.error("Invalid TopoJSON structure:", indiaTopoJson);
      }
    } catch (error) {
      console.error("Error processing TopoJSON:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  if (loading) return <p>Loading Map Data...</p>;

  // Ensure data is available before rendering
  if (!mapData || !indicatorData || !selectedGas) return <p>Loading map data...</p>;

  // Function to determine color based on gas value and risk levels
  const getColorForState = (state) => {
    const normalizedState = normalize(state);
    console.log(`Processing State: ${state} -> Normalized: ${normalizedState}`);

    if (!mapData || !mapData.map_info) {
      console.error("Invalid mapData structure:", mapData);
      return "#E5CCE5"; // Default color if no data
    }

    const availableStates = Object.keys(mapData.map_info).map(normalize);
    console.log("Available states in mapData:", availableStates);

    // Find the correct key in mapData.map_info
    const matchingStateKey = Object.keys(mapData.map_info).find(
      (key) => normalize(key) === normalizedState
    );

    if (!matchingStateKey) {
      console.warn(`No data found for state: ${normalizedState}`);
      return "#FF033E1A";
    }

    console.log(`Matched State Key: ${matchingStateKey}`);

    const stateData = mapData.map_info[matchingStateKey];

    if (!stateData || !stateData.values || stateData.values === "-") {
      console.warn(`No valid data for state: ${matchingStateKey}`);
      return "#FF033E1A"; // Default color for missing data
    }

    console.log(`State Data for ${matchingStateKey}:`, stateData);

    return colorMapping.low; // Placeholder logic; adjust based on actual risk levels
  };

  return (
    <div className="Map-container-main" style={{ width: "100%", height: "78vh", marginTop: "16px",marginLeft: "-20%" }}>
      {topoJsonData ? (
        <ComposableMap projection="geoMercator" projectionConfig={{ scale: 1050, center: [80, 22.8] }}>
          <Geographies geography={topoJsonData} className="geographies">
            {({ geographies }) =>
              geographies.map((geo) => {
                const state = geo.properties?.st_nm || "Unknown";
                const district = geo.properties?.district || null;
                console.log(`Processing Geography - State: ${state}, District: ${district}`);

                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    onMouseEnter={(event) => {
                      setSelectedState(state);
                      setTooltipPosition({ x: event.clientX, y: event.clientY });
                    }}
                    onMouseLeave={() => setSelectedState(null)}
                    style={{
                      default: {
                        fill: getColorForState(state),
                        stroke: "#FFFFFF",
                        strokeWidth: 1,
                        outline: "none",
                      },
                      hover: {
                        stroke: "#FFFFFF",
                        strokeWidth: 6,
                        fill: getColorForState(state),
                        outline: "none",
                      },
                    }}
                  />
                );
              })
            }
          </Geographies>
        </ComposableMap>
      ) : (
        <p>Loading Map Data...</p>
      )}
          {selectedState && (
        <div
          style={{
            position: "absolute",
            left: tooltipPosition.x + 10,
            top: tooltipPosition.y - 60,
            zIndex: 10,
            boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
          }}
        >
          <MapDetails selectedState={selectedState} mapData={mapData} />
        </div>
      )}
    </div>
  );
};

export default Maps;
