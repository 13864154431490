import React from "react";
import { 
  AreaChart, Area, XAxis, YAxis, Tooltip, 
  ResponsiveContainer, CartesianGrid 
} from "recharts";

const ForestFireChart = ({ data }) => {
  const getRiskColor = (risk) => {
    if (risk === "High") return "#F44C4F";
    if (risk === "Medium") return "#FCCC0A";
    return "#0CAF56";
  };

  const formatMonth = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString("default", { month: "short" }).replace(".", "");
  };

  return (
    <div className="forest-chart-container">
      <h2 className="forest-chart-title">Forest Fire</h2>
      <div className="flex justify-start">
        <ResponsiveContainer width='95%' height={250}>  
          <AreaChart data={data} margin={{ left: 10, right: 10, top: 5, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis 
              dataKey="month" 
              tick={{ fontSize: 12 }}
              tickFormatter={formatMonth} 
              interval={0}  
              padding={{ right: 10 }} // Prevents cutting at the edges
              scale="point" // Ensures proper spacing
            />
            <YAxis 
              tickFormatter={(value) => `${value}%`} 
              tick={{ fontSize: 12 }} 
            />
            <Tooltip 
              formatter={(value, name, props) => [
                <span style={{ color: getRiskColor(props.payload.risk) }}>{`${value.toFixed(2)}%`}</span>, 
                props.payload.risk
              ]} 
            />
            <Area
              type="monotone" // Improved smoothing
              dataKey="avg_percentage"
              stroke="#FF4C4C"
              fill="url(#gradient)"
              fillOpacity={1}
              strokeWidth={2}
              dot={false}
              activeDot={{ r: 8 }}
            />
            <defs>
              <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" stopColor="#F44C4F" stopOpacity={1} />
                <stop offset="100%" stopColor="rgba(244, 76, 79, 0.2)" stopOpacity={1} />
              </linearGradient>
            </defs>
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default ForestFireChart;
