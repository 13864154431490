import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Dropdown, Menu, Checkbox, Button, message } from 'antd';
import { DownloadOutlined, DownOutlined, CloseOutlined } from '@ant-design/icons';
import generateIcon from '../../../assets/Emission/Report/generate.svg';
import reportIcon from '../../../assets/Emission/Sidebar/logo4.svg';
import userIcon from '../../../assets/SVG/user.svg';
import locationIcon from '../../../assets/Emission/EmissionAnalytics/location.svg';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { ReactComponent as CalendarIcon } from '../../../assets/Emission/MapAnalytics/calendar.svg';
import numericIcon from '../../../assets/SVG/numeric.svg';
import lineChartIcon from '../../../assets/SVG/lineChart.svg';
import piechartIcon from '../../../assets/SVG/pieChart.svg';
import locationTabler from '../../../assets/SVG/locationTabler.svg';
const ReportModal = ({ visible, closeModal }) => {
  const [selectedLocation, setSelectedLocation] = useState('Delhi');
  const [selectedTime, setSelectedTime] = useState('Past 6 Months');
  const [locations, setLocations] = useState([]);
  const [selectedMaps, setSelectedMaps] = useState([]);
  const [selectedMetrics, setSelectedMetrics] = useState([]);

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BE_URL}/emissions/areas/`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('access_key')}`,
            },
          }
        );
        const sortedLocations = response.data.areas.sort((a, b) => a.localeCompare(b));
        setLocations(sortedLocations);
      } catch (error) {
        console.error('Error fetching locations:', error);
      }
    };

    fetchLocations();
  }, []);

  const locationMenu = (
    <Menu
      onClick={(e) => {
        setSelectedLocation(e.key);
      }}
    >
      {locations.map((location) => (
        <Menu.Item
          key={location}
          style={{
            backgroundColor: selectedLocation === location ? '#E6F7FF' : '',
          }}
        >
          {location}
        </Menu.Item>
      ))}
    </Menu>
  );

  const timeMenu = (
    <Menu
      onClick={(e) => {
        setSelectedTime(e.key); // Store the key instead of the label
      }}
    >
      {Object.entries({
        '1_month': 'Past 1 Month',
        '3_months': 'Past 3 Months',
        '6_months': 'Past 6 Months',
        '1_year': 'Past 1 Year',
      }).map(([key, label]) => (
        <Menu.Item
          key={key}
          style={{
            backgroundColor: selectedTime === key ? '#E6F7FF' : '',
          }}
        >
          {label}
        </Menu.Item>
      ))}
    </Menu>
  );
  
  const handleCheckboxChange = (type, setState, state) => {
    setState((prev) =>
      prev.includes(type) ? prev.filter((item) => item !== type) : [...prev, type]
    );
  };

const handleDownload = (card) => {
  const pdf = new jsPDF();
  pdf.setFontSize(16);
  pdf.text("Report Details", 20, 20);
  
  pdf.setFontSize(12);
  pdf.text(`Title: ${card.title}`, 20, 30);
  pdf.text(`Created on: ${card.createdAt}`, 20, 40);
  pdf.text(`Created by: ${card.createdBy}`, 20, 50);
  pdf.text(`Location: ${card.location}`, 20, 60);
  pdf.text(`Time Period: ${card.timePeriod}`, 20, 70);

  if (card.metrics.length > 0) {
    pdf.text("Metrics Included:", 20, 80);
    card.metrics.forEach((metric, index) => {
      pdf.text(`- ${metric.name}`, 25, 90 + index * 10);
    });
  }

  pdf.save(`${card.title}.pdf`);
};
  const handleGenerateReport = async (shouldDownload = false) => {
    const inputs = document.querySelectorAll('.form-input'); // Selects all inputs with the class form-input
    const reportTitle = inputs[0].value.trim(); // First input field (Report Title)
    const CreatedBy = inputs[1].value.trim(); // Second input field (Created By)
  
    if (!reportTitle) {
      message.error('Please enter a report title');
      return;
    }
  
    const mapType = selectedMaps.length > 0 ? selectedMaps[0].toLowerCase().replace(' ', '_') : 'no_map';
    const payload = {
      title: reportTitle,
      location: selectedLocation,
      map_type: mapType,
      include_averages: selectedMetrics.includes('Averages'),
      include_comparisons: selectedMetrics.includes('Comparisons'),
      include_trends: selectedMetrics.includes('Trends'),
      author: CreatedBy,
      timeframe: selectedTime,
    };
  
    const accessToken = localStorage.getItem('access_key');
  
    try {
      const response = await axios.post(
        'https://pench-deforestation-be-rxncfgcjfa-el.a.run.app/emissions/create-report/',
        payload,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
  
      message.success('Report generated successfully!');
      console.log('Report Response:', response.data);
  
      // ✅ Close the modal
      closeModal();
  
      // ✅ Refresh the page after a short delay (optional)
      setTimeout(() => {
        window.location.reload();
      }, 500); // Adding a short delay to ensure smooth transition
  
      // ✅ If shouldDownload is true, trigger the PDF download
      if (shouldDownload) {
        handleDownload({
          title: payload.title,
          createdAt: new Date().toLocaleDateString(),
          createdBy: payload.author,
          location: payload.location,
          timePeriod: payload.timeframe,
          metrics: selectedMetrics.map(metric => ({ name: metric })),
        });
      }
    } catch (error) {
      message.error('Failed to generate report');
      console.error('API Error:', error);
    }
  };
  return (
    <Modal
      title=""
      visible={visible}
      onCancel={closeModal}
      footer={null}
      width={825}
      height={730}
      closable={false}
      bodyStyle={{
        height: '94vh',
        overflowY: 'hidden',



      }}
      className='report-modal-container-custom'
      style={
        {
          top: '0px',
          bottom: '0px',
          overflowY: 'auto',
          right: '3px'
        }
      }

    >
      <div className="modal-header">
        <h2>Generate Report</h2>
        <CloseOutlined className="report-close-icon" onClick={closeModal} />
      </div>
      <p>Select the fields from below to customize and generate your report.</p>
      <div className="report-divider"></div>
      <div className='report-modal-container-tilte'>
        <div className="form-group">
          <label>Report Title</label>
          <div className="input-wrapper">
            <img src={reportIcon} alt="Report Icon" className="input-icon" />
            <input type="text" placeholder="title goes here..." className="form-input" />
          </div>
        </div>
        <div className="form-group">
          <label>Created By</label>
          <div className="input-wrapper">
            <img src={userIcon} alt="User" className="input-icon" />
            <input type="text" placeholder="Name here..." className="form-input" />
          </div>
        </div>
      </div>
      <div className="form-group">
        <label>Select Locations</label>
        <p>Choose the region you'd like to generate a report for.</p>
        <Dropdown overlay={locationMenu} trigger={['click']}>
          <div className="report-dropdown-container">
            <div className='report-dropdown-location'>
              <img src={locationTabler} alt="Location Icon" className="location-icon" />
              <span className="report-dropdown-label">{selectedLocation}</span>
            </div>
            <DownOutlined style={{ color: '#025864' }} />
          </div>
        </Dropdown>
      </div>

      <div className="form-group">
        <label>Map Type</label>
        <p>Select the map style(s) you'd like to include in your report.</p>
        <div className="Map-Dropdown-options">
          {['Heat Map', 'Bubble Map', 'No Map'].map((type) => (
            <div className="Map-Dropdown-option" key={type}>
              <div className="custom-checkbox">
                <Checkbox
                  checked={selectedMaps.includes(type)}
                  onChange={() => handleCheckboxChange(type, setSelectedMaps, selectedMaps)}
                />
                <span>{type}</span>
              </div>
              <p>
                {type === 'Heat Map'
                  ? 'Visualizes emission intensity geographically.'
                  : type === 'Bubble Map'
                    ? 'Spot emission sources visually.'
                    : 'No map will be included.'}
              </p>
            </div>
          ))}
        </div>

      </div>

      <div className="form-group">
        <label>Data Period</label>
        <p>Choose the time period for your report to focus on.</p>
        <Dropdown overlay={timeMenu} trigger={['click']}>
          <div className="report-dropdown-date-container">
            <CalendarIcon style={{ marginRight: '8px', height: '18px', width: '18px' }} />
            <span className="report-dropdown-label-date">{selectedTime}</span>
            <DownOutlined style={{ color: '#025864' }} />
          </div>
        </Dropdown>
      </div>

      <div className="form-group">
        <label>Choose Metrics to Include</label>
        <p>The metrics are displayed as interactive charts tailored to your selected time period for better insights.</p>
        <div className="Map-Dropdown-options">
          {['Averages', 'Comparisons', 'Trends'].map((type) => (
            <div className="Map-Dropdown-option" key={type}>
              <div className="custom-checkbox">
                <Checkbox
                  checked={selectedMetrics.includes(type)}
                  onChange={() => handleCheckboxChange(type, setSelectedMetrics, selectedMetrics)}
                />

                <span>{type}</span>
              </div>
              <div className="subHeading-icon">
                <div className="checkbox-icon-container">
                  <img
                    src={
                      type === 'Averages'
                        ? numericIcon // Replace with the path to the average icon
                        : type === 'Comparisons'
                          ? piechartIcon // Replace with the path to the comparison icon
                          : lineChartIcon // Replace with the path to the trend icon
                    }
                    alt=''
                  />
                  <span className="icon-heading-text">
                    {type === 'Averages'
                      ? 'Numeric Analytics'
                      : type === 'Comparisons'
                        ? 'Donut Chart'
                        : 'Line Chart'}
                  </span>
                </div>
                <p>
                  {type === 'Averages'
                    ? 'Average emission levels over selected period.'
                    : type === 'Comparisons'
                      ? 'Compare emissions across different categories.'
                      : 'Trends in emission changes over time.'}
                </p>
              </div>
            </div>
          ))}

        </div>


      </div>
      <div className="report-divider"></div>
      <div className="report-modal-footer">
        <span className='report-modal-footer-text'>Reports are available for download in PDF format for easy sharing and offline access.</span>
        <div className="button-group">
          <Button className="generate-button" icon={<img src={generateIcon} alt="Generate Icon" />} type="primary" onClick={() => handleGenerateReport(false)}>
            Generate
          </Button>
          <Button className="download-button" icon={<DownloadOutlined />} type="default" onClick={() => handleGenerateReport(true)} >
            Generate & Download
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ReportModal;
