import React, { useState, useContext, useEffect } from "react";
import { Button, Modal, DatePicker, Space, Select } from "antd";
import dayjs from "dayjs";
import calender from "../../assets/SVG/calender.svg";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { addMonths, format } from "date-fns";
import TadobaContext from "../Context/TadobaContext";
dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";
// const dateFormat = "DD-MM-YYYY";
const weekFormat = "MM/DD";
const monthFormat = "YYYY/MM";
const { Option } = Select;

const displayFormat = "DD-MM-YYYY";
const internalFormat = "YYYY-MM-DD";
const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY", "DD-MM-YYYY", "DD-MM-YY"];
const customFormat = (value) => `custom format: ${value.format(dateFormat)}`;
const customWeekStartEndFormat = (value) =>
  `${dayjs(value).startOf("week").format(weekFormat)} ~ ${dayjs(value)
    .endOf("week")
    .format(weekFormat)}`;

// const [fromMonth,setFromMonth] = useState();
// const [toMonth,settoMonth] = useState();
// const [fromYear,setFromYear] = useState();
// const [toYear,settoYear] = useState();
const FrequencyModal = ({
  title,
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  selectedRange,
  setSelectedRange,
  setIsLoading,
  setIsLoading1,
  setIsLoading2,
  fetchMapData,
  getHistoricalUri1,
  getHistoricalUri2,
  // onSubmitApiCall
}) => {
  const api = useContext(TadobaContext);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    // await onSubmitApiCall();
    // await fetchMapData();
    setIsLoading(true);
    setIsLoading1(true);
    setIsLoading2(true);
    fetchMapData();
    getHistoricalUri1();
    getHistoricalUri2();
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const ranges = [
    {
      value: "1",
      label: "Past 3 months",
    },
    {
      value: "2",
      label: "Past 6 months",
    },
    {
      value: "3",
      label: "Past 9 months",
    },
    {
      value: "4",
      label: "Past 12 months",
    },
    {
      value: "5",
      label: "Past 15 months",
    },
    {
      value: "6",
      label: "Past 18 months",
    },
    {
      value: "7",
      label: "Past 21 months",
    },
    {
      value: "8",
      label: "Past 24 months",
    },
    {
      value: "9",
      label: "Past 27 months",
    },
    {
      value: "10",
      label: "Past 30 months",
    },
    {
      value: "11",
      label: "Past 33 months",
    },
    {
      value: "12",
      label: "Past 36 months",
    },
  ];

  const getFormattedDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const subtractMonths = (date, months) => {
    const newDate = new Date(date);

    const totalMonths =
      newDate.getFullYear() * 12 + newDate.getMonth() - months;

    const newYear = Math.floor(totalMonths / 12);
    const newMonth = totalMonths % 12;

    newDate.setFullYear(newYear);
    newDate.setMonth(newMonth);

    return newDate;
  };

  const handleRangeChange = (value) => {
    const date = toDate;
    console.log("date is", date);

    // const presentDate = getFormattedDate(date);
    let rangeDate;
    if (value === "Past 3 months") {
      rangeDate = getFormattedDate(subtractMonths(date, 3));
    } else if (value === "Past 6 months") {
      rangeDate = getFormattedDate(subtractMonths(date, 6));
    } else if (value === "Past 9 months") {
      rangeDate = getFormattedDate(subtractMonths(date, 9));
    } else if (value === "Past 12 months") {
      rangeDate = getFormattedDate(subtractMonths(date, 12));
    } else if (value === "Past 15 months") {
      rangeDate = getFormattedDate(subtractMonths(date, 15));
    } else if (value === "Past 18 months") {
      rangeDate = getFormattedDate(subtractMonths(date, 18));
    } else if (value === "Past 21 months") {
      rangeDate = getFormattedDate(subtractMonths(date, 21));
    } else if (value === "Past 24 months") {
      rangeDate = getFormattedDate(subtractMonths(date, 24));
    } else if (value === "Past 27 months") {
      rangeDate = getFormattedDate(subtractMonths(date, 27));
    } else if (value === "Past 30 months") {
      rangeDate = getFormattedDate(subtractMonths(date, 30));
    } else if (value === "Past 33 months") {
      rangeDate = getFormattedDate(subtractMonths(date, 33));
    } else if (value === "Past 36 months") {
      rangeDate = getFormattedDate(subtractMonths(date, 36));
    }
    setSelectedRange(value);
    api.setRange(value);
    console.log("range date is", rangeDate);
    setFromDate(rangeDate);
    api.setFromDate(rangeDate);
    // setToDate(toDate)
    console.log("fromdate in frequncymodal is", fromDate);
    console.log(toDate);
  };

  const handleDateChange = (date, dateString) => {
    const formattedDate = date ? dayjs(date).format(internalFormat) : "";
    setToDate(formattedDate);
    api.setToDate(formattedDate);
  };

  console.log(ranges);

  useEffect(() => {
    const range = ranges.find(
      (r) => r.label.toLowerCase() === api.range.toLowerCase()
    );
    if (range) {
      setSelectedRange(range.label);
    }
  }, [api.range]);

  return (
    <>
      <Button
        style={{ border: "none", color: "#16B55F", marginRight: "2rem" }}
        onClick={showModal}
      >
        {/* <span style={{paddingRight:"4px"}}>{format(fromDate, 'MMM')}'{format(fromDate, 'yy')}-{format(toDate, 'MMM')}'{format(toDate, 'yy')}</span> */}
        <img style={{ width: "20px" }} src={calender} />
      </Button>
      <Modal
        title={<div className="Frequency_Modal_title">Set frequency</div>}
        okButtonProps={{
          style: {
            color: "white",
            backgroundColor: "#4A59A7",
            width: "90px",
            marginTop: "1.4rem",
          },
        }}
        okText="Retrieve"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {/* <h1 style={{fontSize:"18px", color:"#A7571D", fontWeight:"500", lineHeight:"21.78px"}}>{`Map ${title } ` }</h1> */}
        <div style={{ display: "flex", gap: "10rem" }}>
          <div>
            <p style={{ color: "#27273B" }}>To</p>
            {/* <Space direction="vertical" size={12}>
              <DatePicker style={{backgroundColor:"#E7F0FE", width:"12rem"}}
                defaultValue={dayjs(toDate, dateFormat)}
                format={dateFormat}
                onChange={(date, dateString) => setToDate(dateString)}
                picker="month"
                // maxDate={dayjs(new Date().getFullYear(), dateFormat)}
              />
            </Space> */}
            <Space direction="vertical" size={12}>
              <DatePicker
                style={{ backgroundColor: "#E7F0FE", width: "12rem" }}
                defaultValue={dayjs(toDate, internalFormat)}
                placeholder="Select date"
                format={displayFormat}
                onChange={handleDateChange}
                picker="month"
              />
            </Space>
          </div>
          <div style={{ marginLeft: "-1rem" }}>
            <p style={{ color: "#27273B" }}>Select Frequency</p>
            {/* <Space direction="vertical" size={12}>
              <DatePicker style={{backgroundColor:"#E7F0FE", width:"12rem", marginLeft:"-4rem"}}
                defaultValue={dayjs(toDate, dateFormat)}
                format={dateFormat}
                onChange={(date, dateString) => setToDate(dateString)}
              />
            </Space> */}
            <Select
              placeholder="Select time range"
              onChange={handleRangeChange}
              defaultValue={selectedRange}
            >
              {ranges.map((range, index) => (
                <Option key={index} value={range.label}>
                  {range.label}
                </Option>
              ))}
            </Select>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default FrequencyModal;
