import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from './container/Dashboard';
import Section from './container/Section';
import Login from "./container/Login";
import AppPage from "./components/Login/LoginDetails"
import './css/index.css';
import './App.css';
import NavigationBar from "./components/NavigationBar";
import Carousels from "./components/Login/Carousel"

function App() {
  return (
    <div className="App">
          <Router>
          {/* {window.location.pathname === "/dashboard" ? "" : <NavigationBar />} */}
            <Routes>
              <Route path="/*" element={<Login />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/section" element={<Section />} />

            </Routes>
          </Router>
    </div>
  );
}

export default App;
