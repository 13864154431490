import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import DashboardContext from "../components/Context/DashboardContext";
import profileLogo from '../assets/Emission/Sidebar/logo5.svg';
import { Button } from "antd";

const Section = () => {
  const { type, setType } = useContext(DashboardContext);
  const navigate = useNavigate(); // Use React Router's navigation

  const handleClickDeforestation = () => {
    setType("Deforestation"); // Update type to "Deforestation"
    navigate("/dashboard"); // Navigate without reloading the page
  };


  const handleClickEmission = () => {
    setType("Emission"); // Update type to "Emission"
    navigate("/dashboard/emission"); // Navigate without reloading the page
  };

  const handleClickForest = () => {
    setType("Forest Fire"); // Update type to "Emission"
    navigate("/dashboard/forest-fire"); // Navigate without reloading the page
  };
  const handleProfileClick = () => {
    navigate("/profile"); // Navigate to profile page
  };

  return (
    <div style={{ position: "relative" }}>
      {/* Heading Section */}
      <div
        style={{
          position: "absolute",
          top: "20px",
          width: "100%",
          textAlign: "center",
          zIndex: 1,
        }}
      >
        <h2 style={{ fontFamily: "Arial, sans-serif", color: "#fff" }}>
          Welcome to our Pench Deforestation Project!
        </h2>
        <p
          style={{
            fontFamily: "Arial, sans-serif",
            color: "#ccc",
            fontSize: "16px",
          }}
        >
          Together, we’ll leverage technology to enhance environment safety and
          address deforestation.
        </p>
      </div>

      {/* Section Layout */}
      <div
        style={{ display: "flex", justifyContent: "space-between" }}
        className="section-main"
      >
        <div className="section-main-left">
          <div>
            <button onClick={handleClickEmission} className="button">
              <span>Emission</span>
            </button>
          </div>
        </div>
        <div className="section-main-right">
          <div>
            <button onClick={handleClickDeforestation} className="button">
              <span>Deforestation</span>
            </button>
          </div>
        </div>
        <div className="section-main-middle">
          <div>
            <button onClick={handleClickForest} className="button">
              <span>Forest Fire</span>
            </button>
            </div>
        </div>
      </div>
            {/* Profile Logo */}
      <div
        style={{
          position: "absolute",
          bottom: "16px",
          right: "10px",
        }}
        onClick={handleProfileClick} 
      >
        <img
          src= {profileLogo}
          className="profile-logo"
          alt= 'profile'
        />
      </div>
    </div>
  );
};

export default Section;
