import React, { useState } from "react";
import TadobaContext from "./TadobaContext";

const TadobaProvider = ({ children }) => {
  const [fromDate, setFromDate] = useState("2024-03-01");
  const [toDate, setToDate] = useState("2024-06-01");
  const [range, setRange] = useState("Past 3 months");

  return (
    <TadobaContext.Provider
      value={{
        fromDate,
        toDate,
        setFromDate,
        setToDate,
        range,
        setRange,
      }}
    >
      {children}
    </TadobaContext.Provider>
  );
};

export default TadobaProvider;
