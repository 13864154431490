import React from "react";
import TadobaContext from "./Context/TadobaContext";
import { useContext } from "react";
import '../css/Navigation.css';
const NavigationBar = () => {
  const api = useContext(TadobaContext);
  const expandHandler = () => {
    api.setExpanded(true);
  };
  return (
    <div className="Nav">
      <ul className="Nav_item1">
        <li className="menu" onClick={expandHandler}>
          <img
            className="menu_img"
            src={require("../assets/Icons/menu.png")} 
            alt=""
          />
        </li>
        <li>
          <img
            className="forest_img"
            src={require("../assets/Icons/forest_20240430083938002010_pench_logo.png")}
          />
        </li>
        <li>
          <div className="Nav_item1_text">
            <span>Pench Tiger Reserve</span>
          </div>
        </li>
      </ul>
      <ul className="Nav_item2">
        <li>
          <div className="Nav_item2_text">
            <span>Powered By</span>
          </div>
        </li>
        <li>
          <img
            className="forest_img_2"
            src={require("../assets/Images/logo.png")}
          />
        </li>
      </ul>
    </div>
  );
};

export default NavigationBar;
