import React, { useState } from 'react';
import noReport from '../../../assets/Emission/Report/null.svg';
import addReport from '../../../assets/Emission/Report/add-report.svg';
import reportIcon from '../../../assets/Emission/Report/add-report.svg';
import ReportModal from './ReportModal';
import ReportCard from './ReportCard';


const ReportDashboard = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const generatedReportsCount = 125; // Dynamic value for generated reports
  const filterCount = 4; // Dynamic value for filter count

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  

  return (
    <div className="Report_Dashboard-container p-4">
      <div className="Report_Dashboard-center-container flex justify-between items-center mb-6">
        <div>
          <h1 className="Report_Dashboard-heading">Generated Reports</h1>
          <p className="Report_Dashboard-subheading">
            {generatedReportsCount} generated reports found.
          </p>
        </div>
          <button
            className="Report_Dashboard-generate-button "
            onClick={openModal}
          >
            <img src={reportIcon} alt='' className='add-report' />
            Generate Report
          </button>
          <button
            className="Report_Dashboard-filter-button flex items-center"
          >
            Filters
            <span className="Report_Dashboard-filter-count">
              {filterCount}
            </span>
          </button>
      </div>
      <div className='Report_Dashboard-card'>
          <ReportCard/>
        </div>
      <ReportModal visible={isModalOpen} closeModal={closeModal} />
    </div>
  );
};

export default ReportDashboard;