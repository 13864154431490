import React, { useState, useEffect } from "react";
import axios from "axios";

const EmissionRateComparison = ({ selectedLocation }) => {
  const [selectedYear, setSelectedYear] = useState("2024");
  const [selectedMonth, setSelectedMonth] = useState("12");
  const [emissionData, setEmissionData] = useState(null);
  const [error, setError] = useState(null); // State for handling errors

  const colors = {
    "Carbon Monoxide": { main: "#173F5F", light: "#173F5F33" },
    "Sulfur Dioxide": { main: "#F0C93A", light: "#FFDB4333" },
    "Nitrogen Dioxide": { main: "#3CAEA3", light: "#3CAEA333" },
    Ozone: { main: "#2576B9", light: "#2576B933" },
  };

  useEffect(() => {
    const fetchEmissionData = async () => {
      const url = `${process.env.REACT_APP_BE_URL}/emissions/monthwise-emissions-comparison/`;
      try {
        const response = await axios.get(url, {
          params: {
            location: selectedLocation,
            month: `${selectedYear}-${selectedMonth}`,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_key")}`,
            "Content-Type": "application/json",
          },
        });
        setEmissionData(response.data.emission_comparison);
        setError(null); // Clear error if data is fetched successfully
      } catch (err) {
        if (err.response && err.response.status === 404) {
          setError("No emission data found for the selected location and time.");
        } else {
          setError("Failed to fetch emission data.");
        }
        setEmissionData(null); // Clear data if there's an error
      }
    };

    fetchEmissionData();
  }, [selectedLocation, selectedYear, selectedMonth]);

  const handleYearChange = (year) => {
    setSelectedYear(year);
    setSelectedMonth("01"); // Reset to January when the year changes
  };

  const handleMonthChange = (month) => {
    setSelectedMonth(month);
  };

  return (
    <div className="emission-rate-comparison">
      <div className="emission-heading">Emission Rate Comparison</div>
      <div className="bubbles">
        {emissionData &&
          emissionData.map((item, index) => {
            const color = colors[item.gas] || { main: "#999", light: "#ccc" };
            return (
              <div
                key={index}
                className="bubble"
                style={{
                  "--size": item.percentage,
                  backgroundColor: color.light,
                  borderColor: color.main,
                  color: color.main,
                }}
              >
                {item.percentage}%
              </div>
            );
          })}
      </div>
      <div className="year-box">
        <select
          className="year"
          value={selectedYear}
          onChange={(e) => handleYearChange(e.target.value)}
        >
          {["2024", "2025"].map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
        <div className="month-navigation">
          {["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"].map((month) => (
            <button
              key={month}
              className={`month-btn ${month === selectedMonth ? "active" : ""}`}
              onClick={() => handleMonthChange(month)}
            >
              {new Date(selectedYear, month - 1).toLocaleString("default", { month: "short" })}
            </button>
          ))}
        </div>
      </div>
      <div className="details">
        {error && <p style={{ color: "red"}}>{error}</p>}
        {emissionData &&
          emissionData.map((item, index) => {
            const color = colors[item.gas] || { main: "#999", light: "#ccc" };
            const trend = item.percent_change > 0 ? "up" : "down";
            return (
              <div key={index} className="detail">
                <div
                  className="circle"
                  style={{ backgroundColor: color.main }}
                ></div>
                <div>
                  <p className="name" style={{ color: color.main }}>
                    {item.gas}
                  </p>
                  <p className="value">
                    {item.average_measurement.toFixed(3)} {item.unit}
                  </p>
                  <p
                    className={`change ${
                      trend === "up" ? "positive" : "negative"
                    }`}
                  >
                    {trend === "up" ? "▲" : "▼"}{" "}
                    {Math.abs(item.percent_change).toFixed(2)}% since last month
                  </p>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default EmissionRateComparison;
