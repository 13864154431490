import React from 'react'
import Navbar from '../components/ForestFire/NavbarForest';
import Dashboard from '../components/ForestFire/ForestMap.jsx/MapDashboard';
const ForestFirePage = () => {
  return (
    <div className='forest-main'>
        <div className='forest-navbar'>
            <Navbar />
        </div>
        
            <div className='forest-dashboard-container'>
                <Dashboard />
            
        </div>
    </div>
  )
}

export default ForestFirePage
