import React, { useState } from 'react';
import EmissionRate from './AvgEmissionRate';
import ForestChart from './ForestChart';
import EmissionTrend from './EmissionTrendGraph';
import SubRegion from './SubRegional';

const AnalyticsComponent = ({ analyticsData }) => {
  const [selectedTab, setSelectedTab] = useState('Analytic');

  return (
    <div className='forest-analytics-container'>
      <div className='forest-analytics-header'>
        <h3 className={selectedTab === 'Analytic' ? 'active-tab' : ''} onClick={() => setSelectedTab('Analytic')}>
          Analytics
        </h3>
        <h3 className={selectedTab === 'Sub-regional' ? 'active-tab' : ''} onClick={() => setSelectedTab('Sub-regional')}>
          Sub-regional analysis
        </h3>
      </div>
      <div className='forest-analytics-divider'></div>

      {selectedTab === 'Analytic' && (
        <>
          <EmissionRate data={analyticsData.ghgRates} />
          <div className='forest-component-divider'></div>
          <ForestChart data={analyticsData.forestFireTrend} />
          <div className='forest-component-divider'></div>
          <EmissionTrend apiData={analyticsData.emissionTrend} />
        </>
      )}

      {selectedTab === 'Sub-regional' && <SubRegion apiData={analyticsData.subRegion} />}
    </div>
  );
};

export default AnalyticsComponent;
