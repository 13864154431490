import React from "react";
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, CartesianGrid } from "recharts";

const EmissionTrend = ({ apiData }) => {
  if (!apiData || !apiData.trend) {
    return <div>No data available</div>;
  }

  const data = Object.keys(apiData.trend).map((month) => ({
    month,
    [apiData.gas]: parseFloat(apiData.trend[month].toFixed(5)), // Gas name as key
  }));

  const formatMonth = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString("default", { month: "short" }).replace(".", "");
  };
  return (
    <div className="forest-chart-container">
      <div className="forest-heading-container">
        <div className="forest-heading">Emission Trend Graph</div>
      </div>
      <ResponsiveContainer width="95%" height={250}>
        <LineChart data={data}>
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis dataKey="month" tick={{ fontSize: 12 }} interval={0} tickFormatter={formatMonth}  padding={{ right: 10 }} />
          <YAxis tick={{ fontSize: 12 }} axisLine={false} tickLine={false} />
          <Tooltip
            formatter={(value) => [`${value.toFixed(5)} ${apiData.unit}`, apiData.gas]} // Tooltip value rounded
            labelFormatter={(label) => `Month: ${label}`}
          />
          <Legend verticalAlign="bottom" align="center" wrapperStyle={{ fontSize: 12 }} />
          <Line
            type="straight"
            dataKey={apiData.gas} // Display gas name in legend
            stroke="#3AA4F0"
            strokeWidth={2}
            dot={false}
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default EmissionTrend;
