import React, { useEffect, useState } from 'react';
import axios from 'axios';
import userImg from '../../../assets/Emission/Navbar/profile-pic.jpg';
import { Switch, Modal } from 'antd';
import { QRCodeCanvas } from 'qrcode.react';

const ProfileDashboard = () => {
  const [userDetails, setUserDetails] = useState(null);
  const [totpSecret, setTotpSecret] = useState('');
  const [totpEnabled, setTotpEnabled] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Fetch user details
  const fetchUserDetails = async () => {
    try {
      const token = localStorage.getItem("access_key");
      const response = await fetch(`${process.env.REACT_APP_BE_URL}/user_profile/profile/`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const userData = await response.json();

      if (response.ok) {
        localStorage.setItem("userdata", JSON.stringify(userData)); // Store in localStorage
        setUserDetails(userData);
        setTotpEnabled(userData?.totp_status || false);
        setTotpSecret(userData?.Secret_key || "");

        // Ensure TOTP status is stored correctly in localStorage
        localStorage.setItem("two_fa_status", userData?.totp_status ? "true" : "false");
      } else {
        const storedData = localStorage.getItem("userdata");
        if (storedData) {
          setUserDetails(JSON.parse(storedData));
        }
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  // Handle TOTP setup
  const handleTotpSetup = async (status) => {
    try {
      const formData = new FormData();
      formData.append("totp_status", status);
  
      const response = await axios.post(
        `${process.env.REACT_APP_BE_URL}/user_profile/totp-secret/`,
        formData, // Send FormData
        {
          headers: { 
            Authorization: `Bearer ${localStorage.getItem('access_key')}`,
            "Content-Type": "multipart/form-data"  // Ensure it's recognized as FormData
          },
        }
      );
  
      setTotpSecret(response.data.totp_secret);
      setTotpEnabled(status);
  
      // Update localStorage to ensure persistence across logins
      localStorage.setItem("two_fa_status", status ? "true" : "false");
  
    } catch (error) {
      console.error('Error fetching TOTP secret:', error);
    }
  };  

  // Generate TOTP URI for QR Code
  const generateTotpUri = () => {
    const issuer = userDetails?.first_name || 'First Name';
    const user = userDetails?.email || 'Email ID';
    return `otpauth://totp/${issuer}:${user}?secret=${totpSecret}&issuer=${issuer}`;
  };

  // Handle 2FA switch change
  const handleSwitchChange = (checked) => {
    setTotpEnabled(checked);
    handleTotpSetup(checked);
  };

  // Handle modal visibility based on TOTP setup
  useEffect(() => {
    setIsModalOpen(totpEnabled && !!totpSecret);
  }, [totpEnabled, totpSecret]);

  console.log(totpEnabled)
  console.log(totpSecret)
  return (
    <div className='user_profile_main'>
      <div className="user_profile_container">
        <div className="user_profile_elem1">
          <div className="user__profile__img__box">
            <div className="user_profile_img">
              <img src={userImg} alt="user" />
            </div>
            <div className="user_profile_other_detail_box">
              <div className="user_profile_detail">
                <span>Name</span>
                <span>
                  {userDetails ? `${userDetails.first_name} ${userDetails.last_name}` : 'First Name Last Name'}
                </span>
              </div>
              <div className="user_profile_detail">
                <span>Email ID</span>
                <span>{userDetails?.email || 'Email ID'}</span>
              </div>
              <div className="user_profile_detail">
                <span>Contact Number</span>
                <span>{userDetails?.phone_number || 'Contact Number'}</span>
              </div>
              <div>
                <span className="user_profile_2fa">2-Factor Authentication (2FA)</span> 
                <Switch
                  style={{ marginLeft: '1rem' }}
                  onChange={handleSwitchChange}
                  checked={totpEnabled}
                  checkedChildren="Enable"
                  unCheckedChildren="Disable"
                />
                <Modal
                  open={isModalOpen}
                  onCancel={() => setIsModalOpen(false)}
                  footer={null}
                  className="totp-modal"
                >
                  <div className="totp-modal-content">
                    <p>Scan this QR code with your Google Authenticator app:</p>
                    <div className="qr-code-container">
                      <QRCodeCanvas value={generateTotpUri()} size={200} /> 
                    </div>
                  </div>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileDashboard;
