import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"; 
import axios from "axios";

const API_BASE_URL = "https://pench-deforestation-be-rxncfgcjfa-el.a.run.app/user_profile/";

// Logout function
export const logout = () => {
    return (dispatch) => {
        dispatch(userSlice.actions.clearUserSession());
        localStorage.removeItem("access_key");
        localStorage.removeItem("refresh_key");
    };
};

// Login function
export const loginUser = createAsyncThunk(
    "user/loginUser",
    async ({ email, password }) => {
        try {
            const response = await axios.get(`${API_BASE_URL}login_2fa_user/`, { 
                params: { username: email, password }
            });

            const { access, refresh, two_fa_status } = response.data;

            // Store tokens in localStorage
            localStorage.setItem("access_key", access);
            localStorage.setItem("refresh_key", refresh);

            return { 
                token: access, 
                refreshToken: refresh,
                twoFaStatus: two_fa_status,
            };
        } catch (error) {
            throw error;
        }
    }
);

// Redux slice
const userSlice = createSlice({
    name: "user",
    initialState: {
        loading: false,
        user: null,
        token: null,
        refreshToken: null,
        error: null,
        login: false,
        isVerified: false,  
        twoFaStatus: null,
    },
    reducers: {
        restoreLogin: (state) => {
            const storedToken = localStorage.getItem("access_key");
            const storedRefresh = localStorage.getItem("refresh_key");

            if (storedToken) {
                state.login = true;
                state.token = storedToken;
                state.refreshToken = storedRefresh;
            } else {
                state.login = false;
                state.user = null;
                state.token = null;
                state.refreshToken = null;
            }
        },
        clearUserSession: (state) => {
            state.login = false;
            state.token = null;
            state.refreshToken = null;
            state.user = null;
            state.isVerified = false;
            state.twoFaStatus = null;
            localStorage.removeItem("access_key");
            localStorage.removeItem("refresh_key");
        },
        setIsVerified: (state, action) => {
            state.isVerified = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginUser.pending, (state) => {
                state.loading = true;
                state.user = null;
                state.error = null;
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                state.loading = false;
                state.token = action.payload.token;
                state.refreshToken = action.payload.refreshToken;
                state.twoFaStatus = action.payload.twoFaStatus;
                state.error = null;
                state.login = true;
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.loading = false;
                state.user = null;
                state.login = false;

                if (action.error.message === "Request failed with status code 401") {
                    state.error = "Access Denied! Invalid Credentials";
                } else {
                    state.error = action.error.message;
                }
            });
    }
});

export const { restoreLogin, clearUserSession, setIsVerified } = userSlice.actions;

export default userSlice.reducer;
